.rate-us {
  display: flex;
  max-width: 895px;
  justify-content: space-between;
  margin-bottom: 40px;
  flex-wrap: wrap;

  @media (min-width: $screen-tablet-portrait) {
    flex-wrap: nowrap;
  }

  &__img-container {
    p {
      font-weight: $fontWeightBold;
      font-size: $fontSizeXMedium;
      line-height: 1.3;
    }

    img {
      max-width: 225px;
    }
  }

  &__text-container {
    max-width: 510px;

    @media (min-width: $screen-tablet-portrait) {
      padding-left: 16px;
    }

    h3 {
      font-size: $baseFontSize;
    }

    p {
      font-size: $baseFontSize;
      line-height: 1.5;
      margin-bottom: 16px;
    }
  }
}
