@import "../../../bps/components/campaignHeader/campaignHeader.scss";

.campaign-header {
  &__close-btn {
    @media (max-width: $screen-tablet-landscape - 1) {
      padding: 21px;
      position: relative;
    }

    @media (min-width: $screen-mobile-large) {
      margin-right: -15px;
    }

    @media (min-width: $screen-tablet-landscape) {
      margin-right: -10px;
    }
  }

  &__container {
    padding-left: 15px;

    @media (max-width: $screen-tablet-landscape - 1) {
      min-height: 60px;
    }
  }

  &__content {
    line-height: 1.4;
    font-size: $fontSizeXSmall;
  }
}
