.ratings {
  $root: &;
  max-width: none;
  position: relative;
  height: auto;
  padding: 7px 0;
  margin: 0 0 0 -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &--disabled {
    justify-content: flex-end;
    flex-flow: row-reverse;
  }

  &__wrapper {
    max-width: none;
    position: relative;
    display: flex;
    flex-flow: row-reverse;
  }

  &__star {
    font-size: 1.2em;
    color: $ratingStarsColor;
    position: relative;
    display: block;
    width: auto;
    height: auto;
    margin: 0 2px;
    cursor: pointer;

    &:first-child {
      margin-right: 0;
    }

    &:last-child {
      margin-left: 0;
    }

    &::before {
      transition: color .5s, opacity .2s, transform .6s cubic-bezier(.175, .885, .32, 2);

      .is-test-env & {
        animation: none !important;
      }
    }

    #{$root}:not(#{$root}--disabled) &:hover {
      color: $ratingStarsHoverColor;

      &::before {
        color: currentColor;
      }
    }

    &--empty {
      #{$root}--disabled &::before {
        opacity: .5;
      }

      #{$root}:not(#{$root}--disabled) &::before {
        opacity: .8;
        transform: scale(.8);
        color: $ratingEmptyStarsColor;
      }
    }

    &--25,
    &--50,
    &--75 {
      @include iconFont(star, after) {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        overflow: hidden;
      }
    }

    &--25::after {
      width: 35%;
    }

    &--50::after {
      width: 50%;
    }

    &--75::after {
      width: 60%;
    }

    @include iconFont(star);
  }
}

.ratings:not(.ratings--disabled) {
  .ratings__star:hover,
  .ratings__star:hover ~ .ratings__star,
  .ratings__star:focus,
  .ratings__star:focus ~ .ratings__star {
    color: $ratingStarsHoverColor;
    cursor: pointer;

    &::before {
      color: $ratingStarsHoverColor;
    }
  }
}

// PLACEHOLDER
/* stylelint-disable-next-line */
ratings {
  min-height: 20px;
  display: block;
}
