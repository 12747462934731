@import '../../../bps/components/servicesListTeaser/servicesListTeaser.scss';

.services-list-teaser {
  $root: &;

  &__wrapper {
    margin: -10px -5px;
    width: calc(100% + 10px);

    @media (min-width: $screen-tablet-portrait) {
      margin: -12px;
    }
  }

  &__service {
    &.service-teaser__button {
      padding: 0 5px;
      margin: 5px 0;

      @media (min-width: $screen-tablet-portrait) {
        padding: 0 12px;
        margin: 12px 0;
      }
    }
  }
}
