.sidebar {
  &__wrapper {
    position: relative;
  }

  &--checkout {
    z-index: 100;

    @media (min-width: $screen-tablet-landscape) {
      height: auto;
      width: 50vw;
      max-width: 515px;
      padding: 0;
    }

    &-small {
      @media (min-width: $screen-tablet-portrait) {
        margin-left: auto;
        max-width: 315px;
      }

      @media (min-width: $screen-tablet-landscape) {
        margin-left: auto;
        max-width: 420px;
      }
    }
  }

  &--absolute {
    position: absolute;
  }

  &--relative {
    position: relative;
  }

  &--fixed {
    @media (max-width: $screen-mobile-landscape-max) {
      position: fixed;
      left: -5%;
      width: 110%;
      bottom: 0;
      height: auto;
      box-shadow: 0 -2px 2px 0 rgba(0 0 0 / .4);
      background: #f5f5f5;
      padding: 0 5%;

      .native-app & {
        bottom: 60px;
      }

      .checkout-step-address--add &,
      .checkout-step-address--change & {
        display: none;
      }
    }

    @media (min-width: $screen-tablet-portrait) {
      position: fixed;
      top: 0;
      margin: 0;
    }
  }

  &--bottom {
    @media (min-width: $screen-tablet-portrait) {
      bottom: 0;
    }
  }

  &__checkout-area {
    text-align: center;
    padding: 0 0 15px;
    margin: auto;

    @media (min-width: $screen-tablet-portrait) {
      text-align: left;
      margin: 0;
      justify-content: flex-end;
      display: flex;
    }

    @media (min-width: $screen-tablet-landscape) {
      max-width: 100%;
    }

    &--float-right {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: $screen-tablet-portrait) {
        align-items: flex-end;
      }
    }

    .sidebar--fixed & {
      padding: 10px 15px 12px;

      @media (min-width: 420px) {
        padding: 15px 0;
        margin: 0;
      }
    }
  }

  &__benefits-area {
    padding: 15px 0 0;

    .sidebar--fixed & {
      display: none;

      @media (min-width: $screen-tablet-portrait) {
        display: block;
      }
    }

    .html-block {
      font-size: $checkoutBoxHtmlFontSize;
    }
  }

  &--only-mobile {
    display: block !important;

    @media (min-width: $screen-tablet-portrait) {
      display: none !important;
    }
  }

  &--only-desktop {
    display: none !important;

    @media (min-width: $screen-tablet-portrait) {
      display: block !important;
    }
  }

  &__summary-area {
    padding: 15px 0 0;
    margin: auto;
    text-align: left;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0;
      padding: 0;
      width: 100%;
      max-width: 98%;
    }

    .sidebar--fixed & {
      display: none;

      @media (min-width: $screen-tablet-portrait) {
        display: block;
      }
    }

    .row {
      margin: 0 !important;
    }
  }

  &__wrapper-right--flex {
    @media (min-width: $screen-tablet-portrait) {
      display: flex !important;
      justify-content: flex-end;
    }
  }
}
