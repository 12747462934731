// PLACEHOLDER
countdown { // stylelint-disable-line
  .countdown-teaser__number {
    visibility: hidden;
    max-width: 75px;
    max-height: 75px;
  }

  .countdown-teaser-small__number {
    visibility: hidden;
    max-width: 15px;
    max-height: 15px;
  }
}
