.wishlist-edit {
  $root: &;
  width: 100%;
  margin: 15px 0;

  &--loading {
    @include spinner() {
      position: fixed;
      z-index: 5;

      @media (min-width: $screen-tablet-portrait) {
        left: 63%;
      }
    }
    pointer-events: none;

    & > * {
      opacity: .5;
    }
  }

  &__list-head {
    position: relative;
    cursor: pointer;

    @media (min-width: $screen-tablet-portrait) {
      border-bottom: $borderDefaultColor 1px solid;
      display: flex;
      padding: 5px 0;
    }
  }

  &__list-name {
    @include iconFont(arrowDownBold) {
      transform: scaleY(-1);
    }

    @include fontSize($fontSizeFont);
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 0;
    margin-bottom: 8px;
    border-bottom: $borderDefaultColor 1px solid;
    position: relative;

    #{$root}__list--open &:before {
      transform: scaleY(1);
    }

    #{$root}__list-head--new & {
      @include iconFont(plus) {
        font-weight: bold;
      }
    }

    &::before {
      transition: transform .2s;
      color: $wishlistEditListNameArrowColor;
      margin-right: 8px;
    }

    @media (min-width: $screen-tablet-portrait) {
      flex-grow: 1;
      border-bottom: 0;
      margin: 0;
    }
  }

  &__name-input {
    height: 35px;

    input {
      @include fontSize($baseFontSize);
    }
  }

  &__list-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: $screen-tablet-portrait) {
      justify-content: flex-end;

      & > .button {
        margin-left: 10px;
      }
    }
  }

  &__list-products.products-list {
    margin: 15px -5px;
  }

  &__list-element {
    margin-bottom: 15px;
  }

  &__product {
    border: 1px solid;

    &--move {
      height: calc(100% - 50px);
    }
  }

  &__select {
    margin-top: -1px;
    height: 50px;

    &--empty {
      visibility: hidden;
    }

    .select__selected-value {
      padding-top: 0;
    }

    .select__wrapper {
      border: 1px solid $inputBorderColor;
    }

    .select__custom-option:first-child {
      display: none;
    }
  }

  #{$root}__product.product-box {
    padding: $productBoxListPadding;
    border: $productBoxWhishListBorder;
  }
}
