.mood-teaser {
  $root: &;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;

  @media (min-width: $screen-tablet-portrait) {
    margin-bottom: 0;
  }

  .category-page__header & {
    margin-bottom: 0;
  }

  &__picture-wrap {
    position: relative;
    display: block;
    z-index: 0;
    width: 100%;
    overflow: hidden;
  }

  &__picture {
    margin: auto;
    z-index: 0;
  }

  &__picture-image {
    width: 100%;
    margin-bottom: -3px;
  }

  &__text-wrap {
    position: absolute;
    z-index: 10;
    padding: 8px 16px;
    color: $moodTeaserTextColor;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    @media (min-width: $screen-tablet-landscape) {
      padding: 16px 32px 24px;
    }

    #{$root}--no-img & {
      position: static;
      color: $moodTeaserNoImgTextColor;
      text-shadow: none;
      padding: 0;
      margin: 0 0 32px;
    }

    .scroll-to-content-button {
      @media (max-width: $screen-tablet-portrait-max) {
        font-size: 11px;

        &::after {
          font-size: 7px;
          padding-left: 2px;
        }
      }
    }
  }
}
