.contact-form {
  &--loader {
    @include spinner();
  }

  &__fields {
    width: 100%;

    @media (min-width: $screen-tablet-landscape) {
      width: 75%;
    }
  }

  &__message {
    @include fontSize();
    font-weight: $fontWeightSemiBold;

    &--type {
      &_error {
        color: $textErrorColor;
      }

      &_success {
        color: $textSuccessColor;
      }
    }
  }
}
