.add-to-cart-position {
  $root: &;
  display: flex;
  font-size: $fontSizeSmall;

  &--unavailable {
    #{$root}__name {
      color: $grayColor;
    }
  }

  &__image-container {
    text-align: center;
  }

  &__info {
    text-align: right;
    flex: 1 1;
  }

  &__image {
    max-width: 125px;
    display: block;

    img {
      max-height: 95px;
      width: auto;
      position: relative;
    }
  }

  &__name {
    color: $grayDarkColor;
    text-decoration: none;
    font-weight: $fontWeightSemiBold;
    line-height: 130%;
    margin-bottom: 4px;
  }

  &__price {
    margin-bottom: 8px;

    .price-info {
      > * {
        font-size: $fontSizeSmall;
        line-height: 130%;
        display: inline-block;
        margin-left: 4px;
      }

      &__unit {
        font-size: $fontSizeXXSmall;
        display: block;
        color: $grayColor;
      }

      &__old {
        color: $grayDarkColor;
      }

      &__currency {
        font-size: 1em;
      }
    }
  }

  &__bottles {
    margin-bottom: 8px;
  }

  &__delete-btn {
    text-decoration: underline;
    color: $grayColor;
    cursor: pointer;
    font-size: $fontSizeXSmall;
  }

  &__relation-btn {
    text-decoration: underline;
    color: $brandPrimaryColor;
    cursor: pointer;
    font-size: $fontSizeXSmall;
    margin: 8px 0;
    display: inline-block;
  }
}
