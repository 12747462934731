.recipe-teaser {
  $linesSpacing: 7px;
  text-align: $recipeTeaserTextAlign;

  &__headline {
    @include fontSize($recipeTeaserHeadlineFontSize);
    text-align: center;
    font-weight: $recipeTeaserHeadlineFontWeight;
    margin: 0 0 40px;
  }

  &__card {
    @media (min-width: $screen-tablet-portrait) {
      display: flex;
    }
  }

  &__image {
    flex: 50% 1;

    img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover; // stylelint-disable-line plugin/no-unsupported-browser-features

      @media (min-width: $screen-tablet-portrait) {
        display: inline;
      }
    }
  }

  &__text {
    flex: 50% 1;
    padding: $recipeTeaserTextPadding;
    background: $recipeTeaserBackgroundColor;
    font-size: $recipeTeaserFontSize;
    color: $recipeTeaserColor;
  }

  &__drink-name {
    @include fontSize($recipeTeaserDrinkNameFontSize);
    font-family: $recipeTeaserDrinkNameFontFamily;
    font-weight: $recipeTeaserDrinkNameFontWeight;
    text-align: $recipeTeaserDrinkNameMobileTextAlign;
    margin: 0 0 15px;

    @media (min-width: $screen-tablet-portrait) {
      text-align: $recipeTeaserDrinkNameDesktopTextAlign;
    }
  }

  &__extra-info-list {
    @include clearList();
    display: flex;
    flex-direction: column;
    font-weight: $fontWeightRegular;
    margin-bottom: 20px;
    font-size: inherit;
    justify-content: space-between;

    @media (min-width: $screen-tablet-portrait) {
      flex-direction: row;
      text-align: center;
    }

    & .recipe-teaser__extra-info-item {
      text-align: $recipeTeaserExtraInfoItemTextAlign;
      line-height: $recipeTeaserExtraInfoItemLineHeight;

      &:not(:last-child) {
        padding: 0 10px 0 0;

        @media (min-width: $screen-laptop) {
          padding: 0 40px 0 0;
        }
      }

      > strong {
        display: block;
      }
    }
  }

  &__ingredients {
    font-size: inherit;
    margin-bottom: 20px;
  }

  &__ingredients-title {
    font-size: $recipeTeaserIngredientsTitleFontSize;
    font-weight: $fontWeightMedium;
    margin: 0 0 $linesSpacing;
  }

  &__ingredients-list {
    @include clearList();
    font-weight: $fontWeightRegular;
  }

  &__ingredient {
    margin: 0 0 $linesSpacing;
  }

  &__preparation {
    font-size: inherit;
  }

  &__preparation-title {
    font-weight: $fontWeightMedium;
    margin: 0 0 $linesSpacing;
    font-size: inherit;
  }

  &__preparation-step-list {
    @include clearList ();
    list-style-type: decimal;
    list-style-position: inside;
    font-weight: $fontWeightRegular;
    line-height: 1.45;
  }

  &__bottom {
    margin: 16px 0 0;

    .button {
      width: $recipeTeaserBottomButtonWidth;
    }
  }
}
