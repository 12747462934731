$xTranslate: 200px;
$yTranslate: 50px;

.image-animation-teaser {
  $animationDuration: 25s;
  overflow: hidden;

  .image-element {
    animation: imageAnimationTeaserY $animationDuration ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    display: block;
    margin-bottom: #{-$yTranslate};
  }

  img {
    animation: imageAnimationTeaserX #{round($animationDuration * .8)} ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    max-width: none;
  }
}

@keyframes imageAnimationTeaserX {
  0% { transform: translate(0); }
  100% { transform: translateX(-$xTranslate); }
}

@keyframes imageAnimationTeaserY {
  0% { transform: translate(0); }
  100% { transform: translateY(-$yTranslate + 5); }
}
