.wishlist-dialog {
  $root: &;
  color: $textBaseColor;

  &--loading {
    #{$root}__lists {
      opacity: .7;
    }
  }

  &__head {
    @include fontSize($fontSizeXXMedium);
    text-align: center;
    font-weight: $fontWeightSemiBold;
    margin: 0 0 24px;
  }

  &__lists {
    @include clearList();
  }

  &__list-item {
    @include fontSize($baseFontSize);
    padding: $wishlistDialogItemPaddingMobile;
    border-bottom: 1px solid $borderDefaultColor;
    display: flex;
    cursor: pointer;

    @media (min-width: $screen-desktop) {
      padding: $wishlistDialogItemPadding;
    }

    &--selected {
      #{$root}__list-icon {
        color: $productBoxWishlistColor;

        &::before {
          content: map-get($icons, heartFilled);
        }
      }

      #{$root}__list-name {
        font-weight: $fontWeightBold;
      }
    }
  }

  &__list-name {
    width: 100%;
  }

  &__list-icon {
    @include iconFont(heartUnfilled);
  }

  &__btn-container {
    margin: 16px 0 0;
    text-align: right;
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: $backgroundGrayColor;
    z-index: 1;
  }

  .input {
    &__wrapper {
      background: $wishlistDialogInputBackground;
    }

    &__field {
      font-weight: $fontWeightRegular;
    }
  }
}

.lightbox--wishlist-dialog {
  max-width: 480px;

  .lightbox__container {
    margin-top: 40px;
  }
}
