.flag {
  $size: 48px;
  border-radius: 50%;
  font-size: $baseFontSize;
  color: $flagTextColor;
  width: $size;
  height: $size;
  line-height: $size;
  text-align: center;
  font-weight: $fontWeightSemiBold;
  margin-bottom: 5px;
  position: relative;
  text-transform: uppercase;
  background-size: contain;

  &--undefined {
    background: $flagUndefinedColor;
  }

  &--bio {
    background-image: url('../staticImages/flag-bio.svg');
  }

  &--enzoExklusivo {
    background-image: url('../staticImages/flag-enzo-exklusivo.svg');
  }

  &--free {
    background-image: url('../staticImages/flag-free.svg');
  }

  &--new {
    background-image: url('../staticImages/flag-new.svg');
  }

  &--newVintage {
    background-image: url('../staticImages/flag-new-vintage.svg');

    span {
      display: none;
    }
  }

  &--nonAlcoholic {
    background-image: url('../staticImages/flag-non-alcoholic.svg');
  }

  &--stoerer-exklusiv {
    border-radius: 0;
    line-height: 1.3;
    padding: 0;
    display: block;
    background-image: url('../staticImages/flag-enzo-exklusivo.svg');

    span {
      display: none;
    }
  }

  &--vegan {
    background-image: url('../staticImages/flag-vegan.svg');
  }

  &--bio,
  &--enzoExklusivo,
  &--free,
  &--new,
  &--newVintage,
  &--nonAlcoholic,
  &--vegan {
    font-size: 0;
  }

  &--nachhaltigkeit {
    font-size: 12px;
  }

  &--link {
    color: $whiteColor !important;
    text-decoration: none !important;
  }

  &--award {
    background: $flagAwardColor;
  }

  &--savings {
    background: $flagSavedPercentageColor;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $fontWeightBold;

    &::before {
      content: '-';
    }
  }

  &--savingsMax {
    background: $flagSavedPercentageColor;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--bottleSize,
  &--stoerer {
    background: $flagStoererColor;
    padding: 8px 12px;
    border-radius: 20px;
    width: auto;
    height: auto;
    line-height: 1;
    display: inline-block;
    font-size: 12px;
    font-weight: $fontWeightRegular;
  }

  &--bottleSize {
    white-space: nowrap;
    text-transform: none;
    min-width: $size;
  }

  .image-element,
  &--image {
    border-radius: 0;
    width: 100%;
    display: flex;
    height: 100%;
    min-height: auto !important;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &--subscription {
    background: $brandSecondaryColor;
    border-radius: 10%;
    font-size: 8px;
  }

  &--quote {
    border-radius: 0;
    width: 100%;
    text-transform: none;
    height: auto;
  }

  &__quote {
    display: inline-block;
    font-size: $flagQuoteFontSize;
    font-weight: $flagQuoteFontWeight;
    color: $flagQuoteColor;
    line-height: 1.3;
    margin: 0 0 3px;
    float: left;
    text-align: right;

    p {
      margin: 0;
      padding: 0;
      text-align: right;
    }
  }

  &__author {
    display: block;
    font-size: $flagAuthorFontSize;
    font-weight: $flagAuthorFontWeight;
    color: $flagAuthorColor;
    line-height: 1.2;
    margin: 0;
    text-align: right;
  }

  &--boxSetUrl {
    position: absolute;
    bottom: 0;
    background: $flagBoxSetUrlColor;
    border-radius: 0;
    width: 86px;
    height: 42px;
    margin-left: -10px;
    cursor: pointer;

    & img {
      width: 43px;
      position: relative;
      top: 3px;
    }

    @include iconFont(arrowRightBold, after) {
      padding-left: 8px;
      font-size: 70%;
      position: relative;
      bottom: 10px;
    }
  }

  &--boxSetLayer {
    background: $sandGrayColor;
    color: $textBaseColor;
    text-transform: none;
    font-weight: $fontWeightRegular;
    width: 100px;
    padding: 5px;
    height: auto;
    cursor: auto;
    margin: 0 0 10px;

    @media (min-width: $screen-tablet-landscape) {
      width: 180px;
      height: 42px;
    }

    & img {
      position: relative;
      top: 1px;
    }

    .flag__boxSetLayer { // stylelint-disable-line
      display: flex;

      &-content {
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;

        @media (min-width: $screen-tablet-landscape) {
          text-align: right;
          flex-direction: row;
        }
      }

      &-text {
        font-size: 12px;
        line-height: 1.2;
        padding: 0 0 4px;
        text-decoration: none;
        flex: 1;

        @media (min-width: $screen-tablet-landscape) {
          font-size: 14px;
          padding: 0 6px 0 0;
        }
      }

      .image-element {
        display: flex;
        justify-content: center;
        width: 38.5px;
        height: 31px;

        @media (min-width: $screen-tablet-landscape) {
          width: auto;
        }
      }

      .info-popup {
        @media (max-width: $screen-mobile-landscape-max) {
          width: 90% !important;
          top: 40%;
          left: 5%;
          bottom: auto;
          margin: 40px 0 0;
        }

        @media (min-width: $screen-tablet-portrait) {
          top: 45px;
          right: -6px;
          bottom: auto;
          left: auto;
        }

        &__info-container {
          display: flex;
        }

        &__info {
          display: none;

          @media (min-width: $screen-tablet-landscape) {
            order: 2;
            margin: 8px 0 8px 6px;
            display: block;
          }
        }

        &__label {
          text-decoration: none;
        }
      }
    }
  }

  &--takeAndPayBenefitText,
  &--takeAndPayBenefitAppliedItemsText {
    width: auto;
    height: auto;
    background: $redDarkColor;
    color: $whiteColor;
    font-size: $fontSizeXSmall;
    border-radius: 0;
    line-height: 1;
    text-transform: none;
    max-width: 90px;
    padding: 3px 5px 5px;
    font-weight: $fontWeightBold;
  }
}
