.footer {
  $root: &;
  //$screen-bp-s: $screen-tablet-portrait;
  $screen-bp-s: $screen-laptop;
  $screen-bp-l: $screen-laptop;
  width: 100%;
  min-height: 90px;
  background: $greenDarkColor;
  display: flex;
  justify-content: center;
  margin: 20px 0 0;

  @media (min-width: $screen-bp-l) {
    margin: 40px 0 0;
  }

  &__row-wrapper {
    display: block;
    padding: 20px 0;

    &:empty {
      border: 0;
    }

    @media (min-width: $screen-bp-l) {
      padding: 40px 0;
    }
  }

  &__row {
    @include container();
    display: block;
  }

  .grid {
    width: 100%;

    .row {
      align-items: stretch !important;
      justify-content: flex-end;
      max-width: 1500px;
      background-color: white;
      border-radius: 1rem;

      @media screen and (min-width: $screen-bp-l) {
        justify-content: space-around;
      }
    }

    .col-lg-4 {
      width: 100%;

      @media (min-width: $screen-bp-s) {
        width: 33.33%;
        margin: 0;
      }
    }

    .col-lg-3 {
      width: 100%;

      @media (min-width: $screen-bp-s) {
        width: 33.33%;
        margin: 0;
      }

      @media (min-width: $screen-bp-l) {
        width: 25%;
      }
    }

    .col-lg-2 {
      width: 100%;

      @media (min-width: $screen-bp-s) {
        width: 33.33%;
        margin: 0;
      }

      @media (min-width: $screen-bp-l) {
        width: calc(100% / 6);
      }
    }
  }

  .grid--raster > .row > div:not(:empty) {
    padding: 0 !important;

    @media (min-width: $screen-bp-s) {
      padding: 4px !important;
      position: relative;
    }
  }

  .html-teaser {
    @media screen and (min-width: $screen-bp-l) {
      height: 100%;
    }
  }

  .newsletter-teaser {
    background: $footerServiceDarkColor;

    &__headline {
      font-size: 14px;
      font-weight: $fontWeightSemiBold;

      @media (min-width: $screen-bp-s) {
        margin: 15px 0 0;
      }

      @media (min-width: $screen-tablet-landscape) {
        margin: 26px 0 0;
        text-align: right;
      }
    }

    &__flag {
      display: none;
    }

    &__form {
      margin-top: 10px;
    }

    &__step-1 {
      @media (min-width: $screen-bp-s) {
        padding: 0 20px;
        display: flex;
        margin: 0;
        justify-content: center;
        flex-wrap: wrap;
      }

      @media (min-width: $screen-desktop) {
        justify-content: flex-start;
      }

      .newsletter-teaser__row {
        @media (min-width: $screen-bp-s) {
          min-width: 50%;
          width: 50%;
        }
      }

      .newsletter-teaser__row--head {
        max-width: none;
        padding: 0;

        @media (min-width: $screen-bp-s) {
          padding: 0 20px 0 0;
        }

        @media (min-width: $screen-desktop) {
          min-width: auto;
          width: auto;
        }
      }

      .newsletter-teaser__row--footer {
        max-width: 100%;
        width: 100%;
        padding: 0;
        margin: -6px 0 0;
      }
    }

    .newsletter-form .form__group:not(.form__group--row):last-child {
      margin: 0;
    }

    .newsletter-form__declaration {
      margin: -2px 0 2px;
      text-align: left;
      font-size: 12px !important;

      a {
        font-size: 12px !important;
      }
    }

    a:not(.no-link) {
      font-weight: inherit;
      text-decoration: underline;
      line-height: inherit;
    }

    &__success {
      background: $whiteColor;

      .newsletter-teaser__headline {
        @include fontSize($fontSizeXXXLarge);
        text-align: center;
      }

      .newsletter-form__declaration {
        text-align: center;
        display: block !important;
      }
    }

    &[data-placeholder] {
      display: block;
      min-height: 332px;

      @media (min-width: $screen-bp-s) {
        min-height: 136px;
      }

      @media (min-width: $screen-bp-l) {
        min-height: 120px;
      }
    }
  }

  &__grid-box {
    background: var(--footer-background);
    padding: 20px;
    color: $footerServiceTextColor;
    margin: 0 0 6px;

    @media (min-width: $screen-bp-s) {
      height: 100%;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      border-radius: 10px;
    }

    & .html-block {
      color: $footerServiceTextColor;
      font-size: inherit;
    }

    > ul {
      .html-block & {
        margin: $footerServiceHeadlinePadding;
      }
    }

    &--blank {
      background: transparent;
      color: $footerServiceBlankColor;
      padding: 0;
    }

    &--dark {
      background: $footerServiceDarkColor;
      color: $textLightColor;

      #{$root}__content li {
        a:not(.no-link),
        a:not(.no-link):visited {
          color: $textLightColor !important;
        }

        a:not(.no-link):hover {
          color: $textLightColor !important;
        }
      }
    }

    &--transparent {
      background: transparent;
      padding: 0 10px;
    }

    &--logo {
      padding: $footerServiceLogoPadding;

      .image-element {
        display: flex;
        justify-content: center;

        img {
          width: $footerServiceLogoWidth;
          height: $footerServiceLogoHeight;
        }
      }
    }
  }

  a:not(.no-link),
  a:not(.no-link):visited {
    font-size: $footerServiceFontSize;
    color: $footerServiceLinkColor;
    font-weight: $fontWeightRegular;
    line-height: $footerServiceLinkLineHeight;
    text-decoration: none;
  }

  a:not(.no-link):hover {
    color: $footerServiceLinkColor !important;
    text-decoration: underline;
  }

  a:not(.no-link)#{$root}__read-more {
    text-decoration: underline;
    margin: $footerServiceReadMoreMargin;
    display: inline-block;
  }

  .accordion {
    background: var(--footer-background);
    margin: 0;

    &.footer__element--top {
      @media screen and (min-width: $screen-desktop) {
        margin-top: 2rem;
      }
    }

    &__headline {
      padding: 0;
      margin: 0;
      font-size: $footerServiceHeadlineFontSize;
      font-weight: 600;
      line-height: 1.4;
      position: relative;
      cursor: auto;
      border-bottom: 1px solid $textDarkestColor;

      @media (min-width: $screen-bp-s) {
        border-bottom: 0 none;
      }

      &::after {
        right: 0;

        @media (min-width: $screen-bp-s) {
          display: none;
        }
      }
    }

    &__inner {
      margin: 0;
      padding: 0;
    }

    .accordion__content {
      border-bottom: 0 none;

      @media (min-width: $screen-bp-s) {
        max-height: none !important;
      }
    }

    &--open .accordion__content {
      @media (max-width: $screen-mobile-landscape-max) {
        max-height: 20000px !important;
      }
    }
  }

  &__headline {
    text-align: left;
    padding: 0;
    margin: 0;
    font-size: $footerServiceHeadlineFontSize;
    font-weight: $fontWeightBold;

    &:first-child {
      margin-top: $footerServiceHeadlineFirstTop;
    }
  }

  &__text {
    &--big {
      @include fontSize($fontSizeXXXMedium);
      font-weight: $fontWeightSemiBold;
      margin-bottom: 8px;
    }

    &--small {
      @include fontSize($fontSizeSmall);
    }
  }

  &__content {
    font-size: $footerServiceFontSize;
    list-style: none;
    text-align: left;
    padding: 0 !important;
    line-height: $footerServiceLineHeight;
    margin: 8px auto;

    @media (min-width: $screen-bp-s) {
      height: 100%;
    }

    li > a {
      display: inline-block;
      width: 100%;
      padding: 0;

      @media (min-width: $screen-tablet-landscape) {
        display: inline-block;
        width: 100%;
      }

      @media (min-width: $screen-desktop) {
        display: inline;
        width: inherit;
      }
    }

    &--nav-line,
    &--nav-line-small,
    &--nav-column {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: 0 0 0 -5px;

      &--max-width {
        @media (min-width: $screen-bp-s) {
          margin: 10px 0 10px -5px;
        }
      }

      & i,
      & > * {
        display: inline-block;
        margin: 2px 5px;
        align-self: center;
      }

      & [class^='sprite-'] {
        display: flex;
      }

      &#{$root}--sprite-wide > * {
        margin: 2px 5px 12px;
      }

      &#{$root}--sprite-border > * {
        box-shadow: 0 0 0 1px $textBaseColor inset;
        border-radius: 3px;
      }

      &--big {
        margin: -6px auto -4px !important;

        @media (min-width: $screen-bp-s) {
          margin: -12px auto -10px !important;
          font-size: 18px !important;
        }

        & > * {
          margin: 10px;

          @media (min-width: $screen-bp-s) {
            margin: 10px 30px;
          }
        }

        a:not(.no-link) {
          font-size: 14px !important;
          line-height: 1.2 !important;
        }
      }

      &--no-margin {
        margin: -4px auto !important;
      }
    }

    &--inline-column {
      display: flex;
      align-items: center;

      .footer__content--text-left {
        text-align: left;
        max-width: 110px;
        line-height: 1.4;
      }
    }

    &--nav-line-small {
      margin: 0 auto;
    }

    &--nav-column {
      flex-direction: column;

      &#{$root}--mobile-row {
        @media (max-width: $screen-mobile-landscape-max) {
          flex-direction: row;
        }
      }

      & > * {
        margin: 4px 0 8px;
      }
    }

    & + & {
      margin-top: 24px;
    }

    &--checkout {
      display: flex;
      justify-content: center;
      margin: $footerServiceCheckoutMargin;

      li {
        display: inline-block;
        min-width: 90px;

        a:not(.no-link),
        a:not(.no-link):visited {
          color: $footerServiceLinkColor;
          text-decoration: none;
          padding: $footerServiceCheckoutPadding;
        }

        a:not(.no-link):hover {
          color: $footerServiceLinkColor;
        }
      }
    }
  }

  &__sprites {
    padding: 4px 0;
    display: flex;
    width: 100%;
    align-items: center;

    > i,
    > a {
      margin: $footerServiceIconMargin;
    }

    i,
    a {
      display: flex;
      text-decoration: none !important;

      &::after {
        background: rgb(0 128 0 / 0.5);
      }
    }

    .icon {
      &::before {
        color: $footerServiceLinkColor;
        font-size: $footerServiceIconFontSize;
      }

      &:hover {
        text-decoration: none !important;
      }
    }
  }

  &__icon-big {
    width: 67px;
    height: 67px;

    @media (min-width: $screen-tablet-sm) {
      width: 50px;
      height: 50px;
    }
  }

  .trusted-shop-badge {
    padding: 0 20px 0 0;

    @media (min-width: $screen-bp-s) {
      padding: 0;
    }
  }

  &__logo {
    img {
      width: 190px;
      display: flex;
      padding: 8px 0;
    }
  }

  .page--landing-page & {
    @media (max-width: $screen-mobile-landscape-max) {
      margin-bottom: 70px;
    }
  }

  &__newsletter {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 1rem;
    height: 100%;
    /* stylelint-disable-next-line */
    justify-content: space-evenly;

    @media screen and (min-width: $screen-bp-l) {
      padding: 0.5rem 2rem;
    }

    p {
      text-align: center;
    }

    &--wrapper {
      height: calc(100% - 4rem);
      margin-top: 2rem;

      @media screen and (min-width: $screen-bp-s) {
        border-right: 1px solid $greenDarkColor;
        padding-right: 1rem;
        margin-right: 1.25rem;
      }
    }

    .button {
      margin-top: 4px;
      min-width: unset;
    }

    &--top,
    &--bottom {
      display: flex;
      flex-direction: column;
    }

    &--top {
      h1 {
        align-self: center;
        text-align: center;
      }
    }

    &--title {
      font-size: 32px;
      text-align: center;
      margin: 0.5rem 0;
      font-family: $fontHeadlineFamily;

      @media screen and (min-width: $screen-bp-l) {
        font-size: 38px;
      }
    }
  }

  &-service > .grid {
    & > .row {
      padding-left: 1rem;
      padding-right: 1rem;

      & > div:not(:first-child) > div {
        @media screen and (min-width: $screen-bp-s) {
          height: 200px;
        }
      }
    }

    & > .row:last-child {
      max-width: unset;
      border-radius: 4px 4px 0 0;
      position: absolute;
      left: 15px;
      width: 100%;
      height: auto;
      background-color: $greenLightColor;
      margin-top: -10px;

      @media screen and (min-width: $screen-bp-s) {
        height: 50px;
      }
    }
  }

  &__zahlungsarten {
    margin-top: 2rem;
    padding: 20px;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $screen-mobile-landscape) {
      flex-direction: row;
    }

    @media screen and (min-width: $screen-bp-s) {
      flex-direction: column;
    }

    p {
      font-size: 11px;
    }

    &--text {
      font-size: 10px !important;
    }

    & > div:last-child {
      padding-top: 0;

      @media screen and (min-width: $screen-mobile-landscape) {
        padding-top: 20px;
      }

      @media screen and (min-width: $screen-bp-s) {
        padding-top: 0;
      }
    }
  }

  &__kontakt {
    padding: 20px;

    p {
      font-size: 14px;
    }
  }

  &__element {
    padding: 20px 5px;

    &--top {
      @media screen and (min-width: $screen-desktop) {
        margin-top: 2rem;
      }
    }

    p, a {
      font-size: $fontSizeSmall;
    }
  }

  &__input {
    &--newsletter {
      border: 1px solid $greenDarkColor;
      border-radius: 10px;
      padding: 0.25rem 0.5rem;
      height: 40px;
    }
  }

  &__serviceseiten {
    margin-top: 8px;

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @media screen and (min-width: $screen-bp-s) {
        flex-direction: row;
      }
    }
  }

  &__versand {
    .footer__sprites {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }
}
