.filter-category {
  margin-top: 15px;

  &__head {
    @media (max-width: $screen-tablet-portrait) {
      display: none;
    }
  }
}

// PRELOADER

/* stylelint-disable-next-line */
filter-category,
[is="filter-category"] {
  .filter-category__preloader {
    @include componentPreloader() {
      display: block;
      width: 100%;
      height: 30px;
      margin: 5px 0;
    }
  }
}
