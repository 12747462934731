.global-app-message {
  &.modal:not(.modal--inline) {
    max-width: 660px;
  }

  &__content {
    display: flex;
    position: relative;
    min-height: 30px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 20px 0 0;
    line-height: 1.3;

    @media (min-width: $screen-tablet-portrait) {
      min-height: 60px;
    }

    &--icon {
      &-info {
        padding-left: 40px;

        @media (min-width: $screen-tablet-portrait) {
          padding-left: 70px;
        }

        @include iconFont(thumbUp) {
          font-size: 30px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          color: $brandPrimaryColor;

          @media (min-width: $screen-tablet-portrait) {
            font-size: 50px;
          }
        }
      }
    }
  }

  &__error {
    margin-top: 20px;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    background: $textErrorColor;
    color: $textLightColor;
  }

  h4 {
    margin: 0;
  }

  .modal__header {
    @include fontSize($fontSizeXMedium);
    font-weight: $fontWeightBold;
  }

  pre {
    font-size: 10px;
  }

  .modal__close-button {
    top: 6px;
    right: 6px;
  }
}
