@mixin switchButtonStyle($transform: 'primary>secondary') {
  @if $transform == 'primary>secondary' {
    --button-primary-text-color: #{$buttonSecondaryTextColor};
    --button-primary-background-color: #{$buttonSecondaryBackgroundColor};
    --button-primary-border-color: #{$buttonSecondaryBorderColor};
    --button-primary-hover-background-color: #{$buttonSecondaryHoverBackgroundColor};
    --button-primary-hover-text-color: #{$buttonSecondaryHoverTextColor};
    --button-primary-active-background-color: #{$buttonSecondaryActiveBackgroundColor};
  } @else {
    --button-secondary-text-color: #{$buttonPrimaryTextColor};
    --button-secondary-background-color: #{$buttonPrimaryBackgroundColor};
    --button-secondary-border-color: #{$buttonPrimaryBorderColor};
    --button-secondary-hover-background-color: #{$buttonPrimaryHoverBackgroundColor};
    --button-secondary-hover-text-color: #{$buttonPrimaryHoverTextColor};
    --button-secondary-active-background-color: #{$buttonPrimaryActiveBackgroundColor};
  }
}
