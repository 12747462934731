.lazy-load-image {
  position: relative;

  &__image {
    opacity: 1;
    transition: opacity .1s linear;

    &--not-loaded {
      opacity: 0;
    }

    &--error {
      padding: 10%;
    }
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
    animation: 2s linear infinite lazyLoadImageLoader;

    @include iconFont(loading) {
      font-size: 30px;
      color: $textGrayColor;
      transform: rotateY(-180deg);
    }
  }
}

@keyframes lazyLoadImageLoader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
