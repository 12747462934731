.clipboard-text {
  text-align: center;

  @media (min-width: $screen-tablet-portrait) {
    display: flex;
    justify-content: center;
  }

  &__input {
    color: $brandSecondaryColor;
    text-align: center;
    font-weight: $fontWeightBold;
    opacity: 1 !important;
    cursor: text !important;

    .clipboard-text--big & {
      font-size: 18px;
      background-color: $whiteColor;
      border-color: $brandSecondaryColor;
    }
  }

  &__input-wrapper {
    border-color: $brandSecondaryColor;
    margin: auto;

    @media (min-width: $screen-tablet-portrait) {
      flex-grow: 1;
      max-width: 300px;
      margin: 0;
    }
  }

  &__button {
    margin: 8px 0 0;
    padding: 0 16px;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0 0 0 16px;
      max-width: 350px;
    }

    @media (min-width: $screen-tablet-landscape) {
      padding: 0 32px;
    }
  }

  &--long-button-text {
    @media (min-width: $screen-tablet-portrait) {
      display: block;
    }

    @media (min-width: $screen-tablet-landscape) {
      display: flex;
      justify-content: center;
    }

    .clipboard-text__input-wrapper {
      @media (min-width: $screen-tablet-portrait) {
        margin: auto;
        max-width: 268px;
      }

      @media (min-width: $screen-tablet-landscape) {
        margin: 0;
      }
    }

    .clipboard-text__button {
      @media (min-width: $screen-tablet-portrait) {
        margin: 16px 0 0;
        max-width: 350px;
      }

      @media (min-width: $screen-tablet-landscape) {
        margin: 0 0 0 16px;
      }
    }
  }

  &--text {
    display: inline-block;
    position: relative;
  }

  &__button-text {
    font-size: inherit;
    font-weight: $fontWeightSemiBold;
    text-decoration: none;
    line-height: 1.4;
  }

  &__hidden-input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
}
