.accordion-list {
  $root: &;
  width: 100%;
  max-width: $maxWidthOuter;
  margin-left: auto;
  margin-right: auto;

  &__headline {
    @include fontSize($fontSizeXXXMedium);
    font-weight: $fontWeightRegular;
    margin-bottom: 10px;
  }

  &__accordion.html-block {
    margin: 0 0 16px;

    &:last-child {
      margin-bottom: 0;
    }

    .accordion__headline {
      font-size: 18px;
      font-weight: $fontWeightSemiBold;
      margin: 0;
      padding: $accordionListContentHeadlineMobilePadding;
      border-top: 1px solid $accordionListBorderColor;
      border-left: 1px solid $accordionListBorderColor;
      border-right: 1px solid $accordionListBorderColor;
      color: $accordionListHeadlineColor;
      background: $accordionListHeadlineBackgroundColor;
      transition: all 300ms ease-in-out;

      @media (min-width: $screen-laptop) {
        padding: $accordionListContentHeadlineDesktopPadding;
      }

      &::after {
        color: $accordionListIconColor;
        background: $accordionListHeadlineBackgroundColor;
        width: $accordionListIconWidth;
        height: $accordionListIconWidth;
        border-radius: $accordionListIconWidth;
        border: 1px solid $accordionListIconColor;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 10px;
        right: 10px;
        transform: rotate(0deg);
        transition: transform .3s;
      }
    }

    .accordion__content {
      border-bottom: 1px solid $accordionListBorderColor;
      border-left: 1px solid $accordionListBorderColor;
      border-right: 1px solid $accordionListBorderColor;
      background: $accordionListContentBackgroundColor;
    }

    .accordion__anchor {
      position: absolute !important;
    }

    .accordion__inner {
      margin: 0;
      padding: $accordionListContentInnerMobilePadding;

      @media (min-width: $screen-laptop) {
        padding: $accordionListContentInnerDesktopPadding;
      }
    }

    &.accordion--open {
      > .accordion__headline {
        color: $accordionListHeadlineHoverColor;
        background: $accordionListHeadlineHoverBackgroundColor;
        border-color: $accordionListBorderHoverColor;

        &::after {
          transform: rotate(180deg);
        }
      }

      .accordion__content {
        border-color: $accordionListBorderHoverColor;
      }
    }
  }
}
