@import '../../../bps/components/goTop/goTop.scss';

.go-top {
  transition: background .15s linear, border .15s linear, bottom .3s ease-in-out;
  background: transparent;
  padding: 5px;

  @media (min-width: $screen-desktop-xl) {
    padding: 0;
  }

  &::before {
    left: 51%;
    top: 54%;
  }

  &::after {
    background: $whiteColor;
    content: '';
    border-radius: 100%;
    width: 38px;
    height: 38px;
    border: 2px solid transparent;
    box-shadow: 0 0 4px 1px rgb(0 0 0 / .1);

    @media (min-width: $screen-desktop-xl) {
      width: 48px;
      height: 48px;
    }
  }

  &:hover {
    background: transparent;

    &::after {
      border-color: $textDarkestColor;
    }
  }

  &--sticky {
    bottom: 0;
    right: $goTopIconRightMobile;

    @media (max-width: $screen-tablet-portrait - 1) {
      .page-detail-view & {
        bottom: 50px;
      }
    }

    @media (min-width: 1664px) {
      right: calc(50% - 828px);
    }
  }
}
