.usp-teaser {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: $whiteColor;
  border-radius: 9px;
  padding: 0.5rem 1rem;
  margin-bottom: 8px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__check {
    margin-right: 8px;
    display: flex;
    align-items: center;

    @include iconFont(check, after) {
      color: $greenLightColor;
    }
  }

  &__icon {
    margin-right: 8px;
    display: flex;
    align-items: center;
    min-width: 30px;
    justify-content: center;

    &--truck {
      @include iconFont(truck, after) {
        color: $uspTeaserIconColor;
        font-weight: $fontWeightBold;
      }
    }

    &--clock {
      @include iconFont(clock, after) {
        color: $uspTeaserIconColor;
        font-weight: $fontWeightBold;
      }
    }

    &--wallet {
      @include iconFont(wallet, after) {
        color: $uspTeaserIconColor;
        font-weight: $fontWeightBold;
      }
    }
  }

  &__content {
    flex-grow: 1;
  }
}
