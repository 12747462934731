.general-message {
  position: relative;
  padding: 10px 20px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
  border-radius: $generalMessageBorderRadius;

  &--type {
    &_info {
      background: $infoColor;
    }

    &_error {
      background: $errorColor;
    }

    &_warning {
      background: $warningColor;
    }

    &_waiting {
      background: $waitingColor;
    }

    &_success {
      background: $successColor;
    }

    &_milesandmore {
      background: $milesAndMoreColor;
    }
  }

  &__messages-list {
    @include clearList();
  }

  &__message {
    color: $textLightColor;
    font-size: $baseFontSize;
    line-height: 140%;

    a {
      color: $textLightColor;
      text-decoration: underline;
    }
  }

  &__close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    cursor: pointer;

    @include iconFont('close') {
      font-size: 10px;
      color: $textLightColor;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media (min-width: $screen-tablet-portrait) {
      width: 24px;
      height: 24px;
      position: absolute;

      &::before {
        font-size: 12px;
      }
    }
  }
}
