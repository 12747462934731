.video-player {
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;

  &__preview-img {
    max-width: 100%;
    position: absolute;
    z-index: 0;
    filter: brightness(.5); // stylelint-disable-line  plugin/no-unsupported-browser-features

    .video-player--activated & {
      display: none;
    }
  }

  &__preview-btn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 2;

    &:hover::before {
      background: none;
    }

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: '';
    }

    &::before {
      border-radius: 50%;
      background: none;
      border: 5px solid $whiteColor;
      width: 62px;
      height: 62px;
      transform: translate(-50%, -50%);
      transition: background-color .2s;
      box-sizing: border-box;
    }

    &::after {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 0 10px 17px;
      border-color: transparent transparent transparent $whiteColor;
      transform: translate(-40%, -50%);
    }
  }

  iframe {
    position: relative;
    width: 100%;
  }
}
