.availability-info {
  font-size: $availabilityInfoFontMobile;

  @media (min-width: $screen-tablet-portrait) {
    font-size: $availabilityInfoFontDesktop;
  }

  &__label {
    display: block;
    color: $grayDarkColor;
    font-size: $availabilityInfoFontMobile;
    font-weight: $availabilityInfoBold;
    line-height: 1.2;
    text-decoration: none;
    text-align: right;
    margin: $availabilityInfoLabelMargin;
    position: relative;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $availabilityInfoFontTablet;
      margin: 0;
    }

    @media (min-width: $screen-tablet-landscape) {
      font-size: $availabilityInfoFontDesktop;
    }

    .availability-info--label::before {
      margin-right: 4px;
    }

    &--available {
      color: $availableColor;

      .availability-info--label::before {
        background-color: $availableColor;
      }
    }

    &--later-available {
      color: $laterAvailableColor;

      .availability-info--label::before {
        background-color: $laterAvailableColor;
      }
    }

    &--sold-out {
      color: $soldOutColor;

      .availability-info--label::before {
        background-color: $soldOutColor;
      }
    }
  }

  &--label {
    display: inline-block;
    font-weight: $availabilityInfoBold;
    text-transform: $availabilityInfoTextTransform;

    &::before {
      position: relative;
      display: inline-block;
      content: "";
      border-radius: 50%;
      width: $availabilityInfoDotMobile;
      height: $availabilityInfoDotMobile;
      margin: -1px 0 2px;
      vertical-align: middle;
      background-color: $grayLightColor;

      @media (min-width: $screen-tablet-portrait) {
        width: $availabilityInfoDotDesktop;
        height: $availabilityInfoDotDesktop;
        margin: 0 5px 3px $availabilityInfoSpaceMinusLeft;
      }
    }
  }

  &--statement {
    display: block;
    font-size: $availabilityInfoFontMobile;
    font-weight: $fontWeightRegular;
    line-height: 1.3;
    text-decoration: none;
    margin: 0;
    position: relative;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $availabilityInfoFontTablet;
      line-height: 1.4;
    }

    @media (min-width: $screen-tablet-landscape) {
      font-size: $availabilityInfoFontDesktop;
    }

    &::before {
      position: absolute;
      top: 5px;
      left: $availabilityInfoSpaceMinusLeft;
      margin: 0;
    }
  }

  &__statement {
    &--label {
      display: block;
      font-weight: $availabilityInfoBold;
      text-transform: uppercase;
    }

    &--text {
      display: block;
      color: $grayColor;
      font-weight: $fontWeightRegular;
      line-height: 1.3;
    }

    &--date {
      display: block;
      color: $grayDarkColor;
      font-weight: $fontWeightRegular;
      line-height: 1.3;
    }
  }

  &--align-left {
    .availability-info__label {
      text-align: left;
    }
  }
}
