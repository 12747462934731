.search-autosuggest {
  &__input {
    width: 100%;
    position: relative;
    z-index: 2;
    padding: 0;

    .container & {
      z-index: 492;
    }
  }

  .input__wrapper {
    height: 40px;
    border: 0 none;
    padding: 4px 12px 8px;
    position: relative;
    background: $searchAutosuggestBackgroundColor;

    &::after {
      display: none;
    }

    .input__field {
      padding: 0 36px 0 6px;
      border-bottom: 1px solid $inputBorderColor;

      &::placeholder {
        color: $grayColor;
        opacity: 1;
        font-size: $inputFieldFontSizeMobile;

        @media (min-width: $screen-tablet-portrait) {
          font-size: $inputFieldFontSizeDesktop;
        }
      }

      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
      }

      &::-ms-reveal,
      &::-ms-clear {
        display: none;
        width: 0;
        height: 0;
      }
    }
  }

  .input__button {
    background: transparent;
    box-shadow: none;
    position: absolute;
    right: 0;
    top: -2px;
    padding: 0 22px 0 10px;
    margin: 0 5px 0 0;
    z-index: 11;

    .container & {
      z-index: 495;
    }

    &::after {
      font-size: 19px;
      font-weight: $fontWeightRegular;
      color: $headerSearchIconColor;
    }
  }

  .input__button-clear {
    background: transparent;
    box-shadow: none;
    position: absolute;
    right: 35px;
    top: 7px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    z-index: 11;
    opacity: 0;
    transition: opacity .3s;

    &--active {
      opacity: 1;
    }

    .container & {
      z-index: 495;
    }

    @include iconFont('close', after) {
      color: $grayColor;
      font-size: 10px;
      align-self: center;
    }
  }

  &__suggestions-flyout {
    position: absolute;
    z-index: 1;
    width: calc(100% + 10px);
    box-shadow: 0 0 10px 0 rgb(0 0 0 / .25);
    padding: 55px 20px 20px;
    top: -5px;
    left: -5px;
    background: $backgroundWhiteColor;

    .container & {
      z-index: 491;
    }
  }

  &__suggestions-list-wrapper {
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
    position: relative;
  }

  &__suggestions-list--left {
    min-width: 100%;

    @media (min-width: $screen-tablet-portrait) {
      min-width: 50%;
    }

    @media (min-width: $screen-desktop) {
      min-width: $searchAutosuggestListLeftWidth;
      max-width: 200px;
      padding: 0 45px 0 0;
    }
  }

  &__suggestions-list--right {
    min-width: 100%;
    position: relative;

    @media (min-width: $screen-tablet-portrait) {
      min-width: 50%;
    }

    @media (min-width: $screen-desktop) {
      min-width: 30%;
      max-width: 375px;

      &::before {
        content: "";
        position: absolute;
        left: -22px;
        top: 12px;
        height: 100%;
        width: 1px;
        background: $grayColor;
      }
    }
  }

  &__suggestions-list--full {
    min-width: 100%;

    @media (min-width: $screen-tablet-portrait) {
      min-width: 50%;
    }

    @media (min-width: $screen-desktop) {
      min-width: $searchAutosuggestListLeftWidth;
      max-width: 400px;
    }
  }

  &__suggestions-list {
    margin-top: 15px;
    position: relative;
  }

  &__suggestions-name {
    display: block;
    font-size: $searchAutosuggestHeadlineFontSize;
    font-weight: $fontWeightSemiBold;
    text-transform: uppercase;
    margin-bottom: 3px;
    color: $searchAutosuggestHeadlineColor;
  }

  &__suggestion {
    font-size: $searchAutosuggestSuggestionFontSize;
    font-weight: $searchAutosuggestSuggestionFontWeight;
    position: relative;
    color: $searchAutosuggestSuggestionColor;
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    flex-flow: row;
    align-items: center;
    padding: 6px 0;

    &:visited {
      color: $searchAutosuggestSuggestionColor;
    }

    &:hover,
    &--active {
      color: $searchAutosuggestHoverColor;
      text-shadow: 0 0 10px rgb(0 0 0 / .15);

      &:visited {
        color: $searchAutosuggestHoverColor;
      }

      > span {
        text-decoration: underline;
        text-decoration-color: $searchAutosuggestHoverBorderColor;
      }
    }

    img {
      display: none;
      max-width: 38px;

      @media (min-width: $screen-tablet-portrait) {
        display: inline;
        padding: 0 11px 3px 0;
        min-width: 38px;
        overflow: hidden;
        font-size: 4px;
      }
    }

    &--link {
      text-decoration: underline;
      padding: 12px 0 6px;
      cursor: pointer;
      width: 100%;

      &:hover,
      &--active {
        color: $searchAutosuggestHoverColor;
        text-shadow: 0 0 10px rgb(0 0 0 / .15);

        &:visited {
          color: $searchAutosuggestHoverColor;
        }
      }
    }
  }

  &__hidden {
    display: none;
  }
}
