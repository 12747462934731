.address-box {
  font-style: normal;
  line-height: 1.3;
  width: 100%;
  position: relative;

  @media (min-width: $screen-tablet-portrait) {
    flex-grow: 2;
  }

  &__line {
    display: block;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    &--hermes-contact {
      padding: 8px 0 0;

      strong {
        font-weight: $fontWeightSemiBold;

        @media (max-width: $screen-mobile-landscape) {
          display: block;
        }
      }
    }

    &--salutation-title,
    &--name,
    &--company,
    &--company-person {
      font-weight: $fontWeightSemiBold;
    }

    &--name,
    &--company-person,{
      margin-bottom: 4px;
    }
  }

  &--checkout {
    margin: 15px 0 20px;
  }

  &__logo {
    display: none;
    position: absolute;
    top: 0;
    right: 0;

    @media (min-width: $screen-mobile) {
      display: block;
    }

    span {
      display: block;
    }
  }
}
