.ul {
  @mixin ulIcon($icon) {
    list-style: none;
    margin: 16px 0 !important;

    li {
      position: relative;
      padding-left: 21px;

      @media (min-width: $screen-tablet-portrait) {
        padding-left: 30px;
      }

      @include iconFont($icon) {
        position: absolute;
        left: 0;
        top: 5px;
        text-decoration: none;
        @content;
      }
    }
  }

  &--checkmark {
    @include ulIcon('checkSmall') {
      font-size: 10px;
    }
  }
}
