@import "../../../bps/components/counterInput/counterInput.scss";

.counter {
  &__input {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--active {
    .counter__button {
      height: 36px;

      &--increase {
        border-radius: 4px 4px 0 0;

        &::after {
          bottom: -25px;
          height: 25px;

          @media (min-width: $screen-desktop) {
            bottom: -22px;
            height: 22px;
          }
        }
      }

      &--decrease {
        border-radius: 0 0 4px 4px;

        &::after {
          top: -25px;
          height: 25px;

          @media (min-width: $screen-desktop) {
            top: -22px;
            height: 22px;
          }
        }
      }
    }
  }
}
