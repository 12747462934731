.tags {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
  float: left;
  width: 100%;

  &__list {
    display: inline-block;
    float: left;
  }

  &__tag {
    display: inline-block;
    background: $tagBackgroundColor;
    color: $tagColor;
    font-size: $fontSizeSmall;
    font-weight: $tagFontWeight;
    margin: 0 6px 6px 0;
    padding: 2px 5px;
    line-height: 1.2;

    a {
      color: inherit;
    }
  }
}
