@import "../../../bps/components/pagination/pagination.scss";

.pagination {
  .select__wrapper::before {
    content: map-get($icons, arrowDown);
  }

  &__button {
    padding: 15px;
    margin: -5px 0 -7px;

    &--prev {
      @include iconFont(arrowDown) {
        transform: rotate(90deg);
      }
    }

    &--next {
      @include iconFont(arrowDown) {
        transform: rotate(-90deg);
      }
      margin-right: -10px;

      @media (min-width: $screen-tablet-landscape) {
        margin-right: -15px;
      }
    }
  }

  &__pages-count {
    margin-left: 8px;
    font-weight: $fontWeightSemiBold;
  }

  .select__wrapper .select__selected-value {
    padding: 0 16px;
  }
}
