// Spinner
@mixin spinner($size: 30) {
  @include iconFont(loading) {
    font-size: #{$size}px;
    display: block;
    position: absolute;
    animation: spin 1.5s infinite linear;
    color: $loaderColor;
    top: 50%;
    left: 50%;
    margin: -#{$size * .5}px 0 0 -#{$size * .5}px;
    z-index: 20;

    @content;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
