.list {
  &--check {
    margin: 0 !important;
    padding: 15px 0 !important;
    list-style: none;

    li {
      position: relative;
      padding: 0 0 6px 36px;
      line-height: $fontLineHeightRegular;

      @include iconFont('checked', before);

      &::before {
        font-size: 19px;
        font-weight: $fontWeightRegular;
        color: $brandPrimaryColor;
        padding: 0;
        vertical-align: middle;
        position: absolute;
        left: 0;
      }
    }
  }

  &--plain {
    @include clearList();
  }
}
