@import '../../../bps/components/serviceTeaser/serviceTeaser.scss';

.service-teaser {
  &::before {
    @include fontSize(40px);
  }

  &__headline {
    font-weight: $fontWeightRegular;
  }

  &__subline {
    font-weight: $fontWeightThin;
  }

  &__button {
    .button {
      @include switchButtonStyle('primary>secondary');
      border-width: 2px;
      font-weight: $fontWeightMedium;
    }
  }
}
