.address-suggestion {
  position: relative;

  &-container {
    position: absolute;
    top: 28px;
    left: 5px;
    right: 5px;
    max-height: 120px;
    overflow-y: auto;
    border: 1px solid $addressSuggestionBorderColor;
    background-color: $addressSuggestionBackgroundColor;
    padding: 5px 20px;
    z-index: 30;

    &--more-spacing {
      top: 124px;
    }
  }

  &-text-input {
    border-color: $addressSuggestionBorderColor;
  }

  &-element {
    margin: 0 -20px;
    padding: 0 20px;
    color: $addressSuggestionColor;
    font-size: $baseFontSize;
    line-height: 22px;
    cursor: pointer;

    &-selected {
      background-color: $addressSuggestionHoverBackgroundColor;
      color: $addressSuggestionHoverColor;
      font-weight: $addressSuggestionSelectedFontWeight;
    }
  }

  &--hide {
    display: none;
  }

  .form__col-6 {
    padding: 0 5px 10px;
  }

  &__myPostPickPostUserId {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span:first-child {
      font-size: $fontSizeXSmall;
      margin: auto 5px auto auto;
    }

    div.input:nth-child(2) {
      width: 80%;
    }
  }
}
