.breadcrumbs {
  $root: &;

  @include container();
  list-style: none;
  margin: $breadcrumbsMargin;
  padding-bottom: 5px;
  z-index: 2;
  position: relative;
  white-space: nowrap;
  overflow: hidden;

  @media (max-width: $screen-tablet-xsm) {
    &--level {
      &_6,
      &_7 {
        & > :not(:nth-last-child(-n+3)) {
          display: none;
        }

        #{$root}__item {
          max-width: 35%;
        }
      }

      &_4,
      &_5,
      &_6,
      &_7 {
        & > :first-child {
          display: inline-block !important;

          #{$root}__link {
            #{$root}__link-name {
              display: none;
            }

            &::before {
              color: $breadcrumbsTextColor;
              content: '...';
            }
          }
        }
      }
    }

    @media (max-width: $screen-mobile-large) {
      &--level {
        &_4,
        &_5,
        &_6,
        &_7 {
          & > :not(:nth-last-child(-n+2)) {
            display: none;
          }

          #{$root}__item {
            max-width: 45%;
          }
        }
      }
    }
  }

  &__wrapper--hidden {
    .breadcrumbs {
      @include visually-hidden();
    }

    .page--category-list &,
    .page--content-page &,
    .page--landing-page &,
    .page--category-overview &,
    .page--category-landingpage & {
      margin-bottom: 24px;
    }

    .page-detail-view & {
      @media (min-width: $screen-tablet-portrait) {
        height: 16px;
      }

      @media (min-width: $screen-desktop) {
        height: 32px;
      }
    }
  }

  &__item {
    display: inline-block;
    position: relative;
    padding-right: 14px;
    margin-right: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: $breadcrumbsTextColor;

    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 1px;
      height: 12px;
      background: $breadcrumbsSeparatorColor;
      right: 4px;
      top: 32%;
      transform: skew(-15deg);
      margin-top: $breadcrumbsLinkAfterMarginTop;
    }

    &:last-child {
      padding: 0;
      margin: 0;
      font-weight: $breadcrumbsLastItemFontWeigth;

      &::after {
        content: none;
      }
    }
  }

  &__link {
    text-decoration: none;
    padding: 2px 0;
  }

  &__link-name {
    @include fontSize($breadcrumbsLinkFontSize);
    color: $breadcrumbsTextColor;
  }
}
