.page-intro {
  $root: &;
  width: 100%;
  margin: 0 0 24px;

  &__text {
    font-size: 12px;
    line-height: 1.2;
    color: $textDarkestColor;
    margin: 0 0 8px;
    flex-grow: 2;

    @media (min-width: $screen-mobile-sm) {
      font-size: 13px;
    }

    @media (min-width: $screen-tablet-portrait) {
      width: 70%;
    }

    @media (min-width: $screen-tablet-landscape) {
      font-size: 14px;
    }

    @media (min-width: $screen-desktop) {
      font-size: 15px;
      margin: 0 0 12px;
      width: 65%;
    }

    p {
      margin: 0;
      padding: 0;
    }

    a {
      color: $textDarkestColor;

      &:hover {
        color: $textHoverColor;
        text-decoration-color: $textHoverColor;
      }
    }

    .products-list-page--no-sidebar & {
      @media (min-width: $screen-mobile-landscape) {
        width: 70%;
      }

      @media (min-width: $screen-tablet-portrait) {
        width: 50%;
      }
    }

    #{$root}--mood & {
      color: $moodTeaserTextColor;
      text-shadow: $moodTeaserTextShadow;

      @media (min-width: $screen-tablet-portrait) {
        width: 70%;
      }

      @media (min-width: $screen-desktop) {
        width: 65%;
      }

      a {
        color: $whiteColor;

        &:hover {
          color: $textHoverColor;
          text-decoration-color: $textHoverColor;
        }
      }
    }

    #{$root}--no-image & {
      width: 100%;
    }
  }
}
