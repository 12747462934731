$page-overlay-duration: .3s;

.page-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(0 0 0 / .8);
  visibility: hidden;
  opacity: 0;
  transition: visibility $page-overlay-duration, opacity $page-overlay-duration;
  z-index: $indexPageOverlay;

  &--visible {
    visibility: visible;
    opacity: 1;
  }

  &--visible-transparent {
    visibility: visible;
    background: rgb(0 0 0 / .1);
  }

  &--transparent {
    opacity: 0;
  }

  &--no-duration {
    transition: opacity 0s;
  }

  .native-app & {
    top: $nativeAppHeaderHeight;
    bottom: $nativeAppBottomNavHeight;
    z-index: $indexPageOverlayNativeApp;

    &--search {
      z-index: $indexPageOverlaySearch;
    }
  }
}
