.image-slider-teaser {
  padding: 8px 0;

  &__image {
    width: 100%;
  }

  &__text {
    display: block;
    margin: 8px 0;
    color: $grayColor;
    font-size: $fontSizeXSmall;
    line-height: 135%;
  }

  .carousel__no-slider .carousel__slide {
    max-width: 100%;
  }

  // PLACEHOLDER
  carousel { // stylelint-disable-line
    display: none;
  }
}

.grid__element--groupedContent1,
.grid__element--groupedContent2,
.grid__element--groupedContent3 {
  .image-slider-teaser {
    .carousel__button {
      &--prev {
        @media (min-width: $screen-tablet-portrait) {
          left: -16px;
        }

        @media (min-width: $screen-desktop-xl) {
          left: -20px;
        }
      }

      &--next {
        @media (min-width: $screen-tablet-portrait) {
          right: -16px;
        }

        @media (min-width: $screen-desktop-xl) {
          right: -20px;
        }
      }
    }
  }
}
