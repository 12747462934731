.campaign-header {
  background: $brandSecondaryColor;
  z-index: 1;
  position: relative;

  &--dummy {
    opacity: 0;
  }

  &--hidden {
    display: none;
  }

  .header__sticky--active & {
    display: none;
  }

  &__container {
    @include container();
    display: flex;
    align-items: center;
    padding-left: 28px;
    padding-right: 0;

    @media (min-width: $screen-tablet-portrait) {
      padding-left: 36px;
    }
  }

  &__content {
    font-size: 10px;
    color: $whiteColor;
    text-align: center;
    padding: 5px 0;
    flex-grow: 1;
    line-height: 110%;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 14px;
      padding: 10px 0;
    }

    a {
      color: $whiteColor;
    }

    [class^='sprite-'] {
      display: inline-block;
      vertical-align: middle;
      margin: 0 2px;
    }
  }

  &__close-btn {
    padding: 10px;
    font-size: 8px;
    cursor: pointer;
    align-self: center;

    @media (min-width: $screen-tablet-portrait) {
      padding: 13px;
    }

    @include iconFont(delete) {
      color: $whiteColor;
    }
  }
}
