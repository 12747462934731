.header-meta-content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__links {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    .html-block {
      line-height: 0.8;
      width: 100%;
    }

    &-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      background: $greenLightColor;

      .header-meta-content__links-flag {
        max-width: 100%;
        height: auto;
        position: absolute;
        left: 0;

        @media screen and (min-width: $screen-tablet-portrait) {
          left: 32px;
        }
      }

      .header-meta-content__links-content {
        text-align: left;
        font-size: 12px;
        padding-left: 84px;
        width: 100%;

        @media screen and (min-width: $screen-tablet-portrait) {
          padding: 0;
          font-size: 14px;

          p {
            text-align: center;
          }
        }
      }
    }
  }
}
