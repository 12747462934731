@import "../../../bps/components/availabilityInfo/availabilityInfo.scss";

.availability-info {
  &--label {
    text-transform: none;
    color: $textDarkestColor;
    font-weight: $fontWeightThin;

    &--available {
      color: $textDarkestColor;

      .availability-info--label::before {
        background-color: $availableColor;
      }
    }

    &--later-available {
      color: $textDarkestColor;

      .availability-info--label::before {
        background-color: $laterAvailableColor;
      }
    }

    &--sold-out {
      color: $textDarkestColor;

      .availability-info--label::before {
        background-color: $soldOutColor;
      }
    }
  }

  &__statement--text {
    color: $textBaseColor;
  }
}
