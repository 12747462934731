@import "../../../bps/components/pageIntro/pageIntro.scss";

.page-intro {
  &__button {
    @include fontSize($fontSizeSmall);
    text-decoration: underline;
    font-weight: $fontWeightRegular;
    letter-spacing: 1px;
    margin-top: 0;
    text-transform: none;

    &::after {
      display: none;
    }
  }

  &__text {
    @include fontSize($baseFontSize);
    line-height: 1.3;
    margin: 0 0 8px;

    h2 {
      @include fontSize($fontSizeXXMedium);
      margin: 8px 0;
      font-weight: $fontWeightMedium;
      font-family: $fontSecondaryFamily;
    }
  }
}
