.additional-content {
  $root: &;
  border-top: $footerSeoBorderTop;
  margin-top: 20px;
  padding: $footerSeoPadding;

  #{$root}__wrapper {
    color: $footerSeoColor;
    width: 100%;
    padding-top: 20px;
  }

  #{$root}__wrapper-split {
    @media (min-width: $screen-tablet-portrait) {
      display: flex;
    }
  }

  #{$root}__text-wrapper {
    font-size: $footerSeoFontSize;

    @media (min-width: $screen-tablet-portrait) {
      max-width: 50%;
      padding-right: 20px;
    }

    @media (min-width: $screen-desktop) {
      max-width: none;
      padding-right: 0;
      flex-shrink: 2;
    }

    h2 {
      font-size: $fontSizeXXMedium;
      font-weight: $fontWeightMedium;
    }

    h3 {
      margin: 0;
      font-weight: $fontWeightMedium;
      font-size: $footerSeoFontSize;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $footerSeoColor;

      a {
        text-decoration: none;
      }
    }

    a {
      color: $footerSeoColor;

      &:visited,
      &:hover {
        color: $footerSeoColor;
      }
    }
  }

  #{$root}__img-container {
    text-align: right;
    padding-top: $footerSeoImgContainerPaddingTop;

    img:nth-child(2) {
      margin-top: $footerSeoImgContainerPaddingTopChild;
    }
  }
}
