.navigation-flyout-teaser {
  width: 100%;
  position: relative;
  padding: 0 0 16px;

  @media (min-width: $screen-desktop-sm) {
    padding: 0 16px 0 8px;
  }

  &__link {
    display: block;
    width: 100%;
    text-align: left;
    position: relative;
  }

  &__img {
    width: 100%;
    display: inline-block;
    background: $navigationFlyoutTeaserBackground;

    @media (max-width: $screen-laptop) {
      position: relative;
      display: block;
      z-index: 0;
      width: 100%;
      overflow: hidden;
      min-height: 185px;

      img {
        height: 100%;
        width: auto;
        position: absolute;
        z-index: 1;
        margin: auto;
        top: -6666px;
        left: -6666px;
        right: -6666px;
        bottom: -6666px;
      }
    }
  }

  &__header,
  &__subline {
    display: inline-block;
    color: $navigationFlyoutTeaserTextColor;
    margin: 0;
    font-size: 32px;
    line-height: 1.4;
    font-weight: $fontWeightSemiBold;
  }

  &__header {
    .columns-4 & {
      font-size: 26px;
    }

    .columns-5 & {
      font-size: 22px;
    }
  }

  &__subline {
    display: block;
    font-size: 14px;
    font-weight: $fontWeightRegular;
  }

  &__box {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 18px 21px;

    @media (min-width: $screen-desktop-sm) {
      padding: 23px 21px;
    }
  }

  &__button {
    font-size: 16px;
    padding: 3px 0;
    color: $navigationFlyoutTeaserTextColor;
    width: auto;
    border: 0 none;
    box-shadow: none;
    display: block;
    text-decoration: $navigationFlyoutTeaserButtonDecoration;
    text-transform: $navigationFlyoutTeaserButtonTransform;

    @media (min-width: $screen-desktop-sm) {
      padding: 7px 0 0;
    }

    &:hover {
      color: $navigationFlyoutTeaserTextColor;
      border: 0 none;
      box-shadow: none;
      text-decoration: $navigationFlyoutTeaserButtonDecoration;
    }
  }
}
