.image-teaser {
  $root: &;
  display: block;
  text-decoration: none;
  width: 100%;
  height: 190px;
  background-size: cover;
  overflow: hidden;
  position: relative;

  @media (min-width: $screen-tablet-portrait) {
    height: 380px;
  }

  @media (min-width: $screen-desktop-sm) {
    &:hover {
      #{$root}__image {
        transform: scale(1.2);
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    transition: transform 1s;
    z-index: 1;
  }

  &__headline {
    position: absolute;
    z-index: 2;
    color: $textLightColor;
    font-size: $fontSizeXLarge;
    font-weight: $fontWeightBold;
    line-height: 1.3;
    text-decoration: none;
    top: 20px;
    left: 20px;
    bottom: 20px;
    right: 20px;

    @media (min-width: $screen-tablet-portrait) {
      max-width: 300px;
      top: 30px;
      left: 30px;
      bottom: auto;
      right: auto;
      font-size: $fontSizeXXXLarge;
    }
  }

  &__subheadline {
    display: none;

    @media (min-width: $screen-tablet-portrait) {
      position: absolute;
      bottom: 30px;
      left: 30px;
      z-index: 2;
    }
  }

  &__overlay {
    @extend #{$root}__image;

    &--color {
      &-info {
        background-color: #39255e;
      }

      &-gray {
        background-color: #20252d;
      }
    }
  }
}
