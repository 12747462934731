.footer-payments {
  &__headline {
    font-size: $baseFontSize;
    display: inline-block;
    color: $textMediumGrayColor;
    font-weight: $fontWeightThin;
    width: 100%;
    padding: 0 0 20px;
    position: relative;
  }

  &__list {
    @include clearList();
    display: flex;
  }

  &__list-item {
    width: 65px;
    height: 35px;
    display: block;
    margin-right: 15px;
    position: relative;

    @media (min-width: $screen-tablet-portrait) {
      width: 85px;
      margin-right: 25px;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    display: block;
  }
}
