.process-teaser {
  $root: &;
  padding: $processTeaserPadding;
  background: $processTeaserBackground;
  color: $processTeaserColor;

  &--space-around {
    margin: 30px 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: 40px 0;
    }
  }

  &--space-bottom {
    margin: 0 0 30px;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0 0 40px;
    }
  }

  &--white {
    background: $processTeaserWhiteBackground;
    color: $processTeaserWhiteColor;
    padding: $processTeaserWhitePadding;
  }

  &--gray {
    background: $processTeaserGrayBackground;
    color: $processTeaserWhiteColor;
    padding: $processTeaserWhitePadding;
  }

  &__headline.headline {
    @include fontSize($fontSizeXXLarge);
    font-weight: $fontWeightRegular;
    padding: 0 0 15px !important;
    margin: 0;

    #{$root}--white & {
      color: $processTeaserWhiteColor !important;
    }
  }

  &--confirmation {
    padding: 15px 0 40px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 0;

    @media (min-width: $screen-tablet-portrait) {
      flex-direction: row;
      justify-content: center;
    }
  }

  &__block {
    padding: 0 20px;
    font-size: $processTeaserFontSize;
    line-height: 1.4;
    font-weight: $fontWeightRegular;
    max-width: 280px;

    @media (min-width: $screen-tablet-portrait) {
      flex-grow: 1;
      width: 15%;
      padding: 0;
      align-self: flex-start;
      max-width: none;
    }

    @media (min-width: $screen-tablet-landscape) {
      font-size: $processTeaserFontSizeDesktop;
      max-width: $processTeaserMaxWidth;
    }

    #{$root}--white & {
      color: $processTeaserWhiteColor !important;
    }

    a {
      font-weight: $fontWeightBold !important;
      text-decoration: underline !important;
      font-size: 12px !important;
      color: $textBaseColor;

      @media (min-width: $screen-tablet-landscape) {
        font-size: 14px !important;
      }

      #{$root}--white & {
        color: $processTeaserWhiteColor !important;
      }
    }

    &--bigger {
      @media (min-width: $screen-tablet-portrait) {
        width: 20%;
        max-width: none;
      }
    }
  }

  &__icon {
    padding: 5px 0;
    font-size: 14px;
    line-height: 1.4;
    font-weight: bold;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0;
      display: flex;
      font-size: 14px;
      line-height: 1.4;
      font-weight: bold;
      height: 76px;
      justify-content: center;
      flex-flow: column;
    }

    &::before {
      font-size: 40px;
      color: $processTeaserIconColor;

      #{$root}--white & {
        color: $processTeaserIconWhiteColor !important;
      }
    }

    &--circle {
      font-size: 37px;
      line-height: 46px;
      font-weight: bold;
      width: 58px;
      height: 58px;
      border-radius: 100%;
      border: 5px solid $processTeaserColor;
      background: $processTeaserBackground;
      color: $processTeaserColor;
      margin: 15px auto;
      padding: 0;

      @media (min-width: $screen-tablet-portrait) {
        padding: 0;
        display: flex;
        justify-content: center;
        flex-flow: column;
      }

      #{$root}--white & {
        color: $processTeaserWhiteColor !important;
        border-color: $processTeaserWhiteColor !important;
        background: $processTeaserWhiteBackground;
      }
    }

    &.icon--bigger,
    &.icon--confirmationBox,
    &.icon--confirmationTracking,
    &.icon--confirmationHome {
      &::before {
        font-size: 60px;
      }
    }

    &.icon--confirmationMail {
      &::before {
        font-size: 40px;
      }
    }

    &.icon--confirmationVersand {
      &::before {
        font-size: 38px;
      }
    }
  }

  &__divider {
    padding: 15px 0 5px;
    display: block;

    @media (min-width: $screen-tablet-portrait) {
      padding: 5px 0;
    }

    @include iconFont(arrowLineRight) {
      @include fontSize(60px);
      padding: 5px 0;
      color: $processTeaserDividerColor;
      transform: rotate(90deg);

      @media (min-width: $screen-tablet-portrait) {
        transform: none;
      }

      @media (min-width: $screen-tablet-landscape) {
        padding: 5px;
      }

      #{$root}--white & {
        color: $processTeaserWhiteColor !important;
      }
    }

    @media (min-width: $screen-tablet-portrait) {
      align-self: flex-start;
      margin: 5px 0 0;
    }

    &--blank {
      padding: 5px;
      margin: 0;

      @media (min-width: $screen-tablet-portrait) {
        padding: 30px;
      }

      &::before {
        content: "";
        padding: 0;
        margin: 0;
      }
    }
  }

  &__button-container {
    background: $processTeaserButtonContainerBackground;
    text-align: center;
    padding: 15px;
    margin: 15px 0 -15px;

    @media (min-width: $screen-tablet-portrait) {
      margin: 15px 15px 0;
    }
  }

  &__small-text {
    font-size: 10px;
    line-height: 1.2;
    padding: 12px 0 0;
  }
}
