.info-popup {
  position: fixed;
  display: inline-block;
  padding: $infoPopupPadding;
  font-size: $infoPopupFontSize;
  line-height: $infoPopupLineHeight;
  background: $infoPopupBackground;
  color: $infoPopupColor;
  border: 1px solid rgb(47 47 45 / .2);
  box-shadow: 0 0 4px 0 rgb(47 47 45 / .2);
  z-index: 620;

  @media (max-width: $screen-mobile-landscape-max) {
    width: 90% !important;
    top: $infoPopupTopMobile;
    left: 5%;
  }

  @media (min-width: $screen-tablet-portrait) {
    position: absolute;
    width: 230px;
    top: auto;
    right: auto;
    bottom: 0;
    left: 20px;

    &--top-left {
      top: 20px;
      right: 20px;
      left: auto;
      bottom: auto;
    }

    &--top-right {
      top: 20px;
      left: 20px;
      right: auto;
      bottom: auto;
    }

    &--bottom-left {
      bottom: 0;
      right: 20px;
      top: auto;
      left: auto;
    }

    &--bottom-right {
      bottom: 0;
      left: 20px;
      right: auto;
      top: auto;
    }
  }

  a {
    color: $infoPopupColor;
    font-size: $infoPopupFontSize;
    line-height: $infoPopupLineHeight;
    text-decoration: underline;
  }

  p {
    padding: 0;
    color: $infoPopupColor;
    font-size: $infoPopupFontSize;
    line-height: $infoPopupLineHeight;
    margin: 0;

    & + p {
      margin: 6px 0 0;
    }
  }

  &__wrapper {
    position: relative;
    display: inline-block;
  }

  &__wrapper-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0 0 0 / .5);
    transition: opacity 500ms;
    opacity: 1;
    z-index: 600;

    @media (min-width: $screen-tablet-portrait) {
      display: none;
    }
  }

  &__info {
    width: $infoPopupInfoSize;
    height: $infoPopupInfoSize;
    background: $infoPopupInfoBackground;
    border-radius: 100%;
    border: $infoPopupInfoBorder;
    display: inline-block;
    text-decoration: none;
    margin: -1px 1px 0 0;
    vertical-align: middle;
    cursor: pointer;
    line-height: 1;

    @include iconFont(infoPopup) {
      font-size: $infoPopupInfoFontSize;
      color: $infoPopupColor;
      display: block;
    }
  }

  &__label {
    cursor: pointer;
    text-decoration: underline;
  }

  &__close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px;
    text-decoration: none !important;
    cursor: pointer;

    @include iconFont(close) {
      font-size: 8px;
      color: $infoPopupColor;
    }
  }
}

// PLACEHOLDER
/* stylelint-disable-next-line */
info-popup {
  visibility: hidden;
  max-height: 20px;
  overflow: auto;
}
