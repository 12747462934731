// Strikethrough
@mixin strikethrough($color) {
  position: relative;

  &::after {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: $color;
    transform: rotate(-5deg);
  }
}
