.navigation-flyout {
  @media (min-width: $screen-desktop-sm) {
    &__wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: $navigationFlyoutPadding;
    }

    &__categories-list {
      @include clearList();
      margin: $navigationFlyoutListMargin;
      display: flex;
      flex-flow: column;

      &--main {
        display: flex;
        margin-top: 0;
        flex-flow: row;
      }
    }

    &__categories-element {
      flex-grow: 1;

      &--main {
        break-inside: avoid; // stylelint-disable-line plugin/no-unsupported-browser-features
        margin-bottom: 0;
        padding: 0 10px;
        position: relative;
        max-width: 25%;
      }

      &--teaser {
        flex-basis: calc(100% / 3);

        img {
          width: 100%;
          margin: 0 0 -4px;
        }
      }

      &--more {
        order: 100;
      }
    }

    &__categories-link {
      display: inline-block;
      font-size: $navigationLinkFontSize;
      font-weight: $navigationLinkFontWeight;
      color: $navigationFlyoutColor;
      text-decoration: none;
      padding: 6px 0;

      &:hover,
      &:visited {
        color: $navigationFlyoutColor;
        text-decoration: none;
      }

      &:hover {
        color: $navigationFlyoutHoverColor;
      }

      &--main {
        font-size: $fontSizeXMedium;
        font-weight: $fontWeightSemiBold;
      }

      &--all {
        color: $navigationFlyoutAllColor;
        position: relative;
        bottom: -8px;
        font-weight: $fontWeightBold;

        &:hover {
          color: $navigationFlyoutAllHoverColor;
          text-decoration: underline;
        }
      }
    }

    &__footer {
      width: 100%;
      padding-top: 25px;
      border-top: 1px solid $borderLightColor;
    }

    mark {
      background: inherit;
      color: inherit;
    }

    &__list-half {
      width: 50%;
      padding: 0 16px;
    }

    &__categories-element--teaser-left {
      width: 50%;
    }

    &__categories-element--teaser-center {
      width: 100%;

      .teasergroup_teaser {
        padding: 0;
      }

      .teasergroup_teaser__items-container { // stylelint-disable-line
        display: flex;
        width: calc(100% + (2 * 12px)) !important;
        margin: 0 -12px -36px !important;
        justify-content: stretch;
        flex-flow: row wrap;

        .teasergroup_teaser__item {
          display: inline-block;
          margin: 0 !important;
          padding: 0 12px 24px !important;

          @media (min-width: $screen-tablet-landscape) {
            &.col-lg-3 {
              width: 25%;
            }

            &.col-lg-4 {
              width: 33.33%;
            }

            &.col-lg-6 {
              width: 50%;
            }

            &.col-lg-12 {
              width: 100%;
            }
          }

          .navigation-flyout-teaser {
            padding: 0 !important;
          }
        }

        &.columns-5 {
          .teasergroup_teaser__item {
            &.col-lg-3 {
              @media (min-width: $screen-tablet-landscape) {
                width: calc(100% / 5);
              }
            }
          }
        }
      }
    }

    &__categories-element--teaser-right {
      width: 62%;
      display: flex;
      justify-content: flex-end;

      .image-text-action-teaser--flyout {
        max-width: 725px;
        width: 100%;
      }
    }

    .image-text-action-teaser--flyout-image {
      .image-text-action-teaser__header {
        color: $whiteColor;
        font-size: 32px;
        line-height: 1.2;
        margin: 0;
        text-shadow: 0 0 5px rgb(0 0 0 / .1), 0 0 12px rgb(0 0 0 / .1);
      }

      .image-text-action-teaser__box {
        position: absolute;
        width: 100%;
        background: none;
        margin: 0;
        min-height: auto;
        bottom: 0;
        padding: 24px;
      }

      .image-text-action-teaser__button {
        padding: 8px 0 6px;
        font-size: 12px;
      }
    }

    .image-text-action-teaser--flyout {
      .product-teaser,
      .image-text-action-teaser__link {
        display: flex;
        flex-direction: row;
      }

      .product-teaser > .product-teaser__link,
      .image-text-action-teaser__img-wrapper {
        width: 50%;
      }

      .product-teaser__box,
      .image-text-action-teaser__box {
        width: 50%;
        background: none;
        margin: 0;
        padding: 0 10px 0 30px;
        min-height: auto;
        position: relative;
        transform: none;
        display: flex;
        flex-flow: column;
      }

      .product-teaser__header,
      .image-text-action-teaser__header {
        font-size: 24px;
        line-height: 1.4;
        margin: 0 0 8px;
      }

      .product-teaser__subline,
      .image-text-action-teaser__subline {
        font-size: 14px;
        line-height: 1.3;
        flex-grow: inherit;
        margin: 0 0 24px;
      }

      .product-teaser__subline {
        margin: 0 70px 24px 0;
      }

      .product-teaser__img-product img {
        width: auto;
      }

      .product-teaser__img-wrapper--product img {
        margin: auto;
        max-height: 250px;
      }

      .product-teaser__specification-link {
        order: 5;
        font-size: 12px;
      }

      .product-teaser__flags {
        top: auto;
        bottom: 72px;
        right: 9px;
      }

      .product-teaser__conversion {
        margin-bottom: 2px;
      }

      .price-info--reduction {
        display: flex;
        justify-content: flex-start;
        flex-flow: row-reverse wrap;
      }

      .product-teaser__price {
        .price-info__old {
          flex: 1 2 auto;
          margin-top: 28px;
        }

        .price-info__unit {
          flex-basis: 100%;
        }
      }

      .product-teaser__flags--flag2 {
        display: none;
      }
    }

    &__footer-link {
      display: inline-block;
      font-size: $baseFontSize;
      color: $textBaseColor;
      text-decoration: none;

      &:hover,
      &:visited {
        color: $textBaseColor;
        text-decoration: none;
      }

      &:hover {
        color: $textHoverColor;
      }

      i {
        font-size: $baseFontSize;
        color: $textHoverColor;
        margin-left: 5px;
      }
    }
  }

  .burger-navi-copy-from {
    display: none;
  }
}
