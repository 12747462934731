.welcome-bar {
  @include fontSize($fontSizeXXMedium);
  color: $whiteColor;
  padding: 12px 8px;
  text-align: center;
  margin: -30px -10px 0;

  @media (min-width: $screen-mobile-large) {
    margin: -30px -15px 0;
  }

  @media (min-width: $screen-tablet-portrait) {
    margin: -40px -20px 0;
    padding: 8px;
  }

  &--full-width {
    max-width: none !important;
  }
}
