.content-page {
  @include sideColumnLayout();
  position: relative;

  &__column {
    display: block;
  }

  &__content {
    font-size: $baseFontSize;
    display: block;
    padding: $contentPagePaddingMobile;
    margin: 10px 0 30px;

    @media (min-width: $screen-tablet-portrait) {
      margin: 10px 0 40px;
      padding: $contentPagePaddingDesktop;
    }

    .html-block:not(.html-block--no-spacing) {
      @include container($maxWidthOuter);
    }
  }
}
