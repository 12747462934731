@import '../../../bps/components/quickfacts/quickfacts.scss';

.quickfacts {
  &__item {
    @include iconFont("", before);

    &--icon-taste,
    &--icon-style {
      &::before {
        content: map-get($icons, taste);
      }
    }

    &--icon-taste {
      .product-quick-facts__value-item > span {
        white-space: nowrap;
      }
    }

    &--icon-grapeVariety {
      &::before {
        content: map-get($icons, grape);
      }
    }

    &--icon-origin {
      &::before {
        content: map-get($icons, worldMap);
      }
    }

    &--icon-category {
      &::before {
        content: map-get($icons, category);
      }
    }

    &--icon-link {
      color: $textDarkestColor;
      text-decoration: underline;
      margin: 10px 0 0;

      .product-quick-facts__value-item--linked > span {
        text-decoration: underline;
      }
    }
  }
}
