.content-index-header {
  .one-page-navigation {
    &__items {
      background: $contentIndexHeaderBackgroundColor;
      padding: $contentIndexHeaderPadding;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @media (min-width: $screen-tablet-landscape) {
        justify-content: space-between;
      }
    }

    &__item {
      @include fontSize($fontSizeXXXMedium);
      color: $contentIndexHeaderTextColor;
      text-transform: uppercase;
      font-weight: $fontWeightRegular;
      padding: 0 5px;
      text-decoration: none;
      line-height: 130%;
      border-bottom: transparent 2px solid;
      transition: border .5s;

      @media (min-width: $screen-tablet-landscape) {
        margin: 0 -5px;
      }

      &--disabled {
        color: $contentIndexHeaderDisabledColor;
        cursor: default;
      }

      &--active {
        font-weight: $fontWeightSemiBold;
        border-bottom: $brandHighlightColor 2px solid;
      }
    }
  }
}
