.hide {
  display: none;

  &--on-mobile {
    display: none;

    @media (min-width: $screen-tablet-portrait) {
      display: block;
    }
  }

  &--on-tablet {
    display: block;

    @media (min-width: $screen-tablet-portrait) {
      display: none;
    }

    @media (min-width: $screen-desktop) {
      display: block;
    }
  }

  &--up-tablet {
    display: block;

    @media (min-width: $screen-tablet-portrait) {
      display: none;
    }
  }

  &--on-desktop {
    display: block;

    @media (min-width: $screen-desktop) {
      display: none;
    }
  }
}
