.tag {
  $root: &;
  padding: $tagPaddingMobile;
  color: $tagColor;
  border: 1px solid $tagBorderColor;
  background: $tagBackgroundColor;
  border-radius: $tagBorderRadius;
  position: relative;
  transition: border .2s, box-shadow .2s;
  text-decoration: none;
  font-size: $tagFontSize;

  @media (min-width: $screen-desktop) {
    padding: $tagPadding;
  }

  &[href] {
    cursor: pointer;
  }

  &:hover {
    text-decoration: none;
    color: $tagHoverColor;
    border-color: $tagBorderHoverColor;
    background: $tagBackgroundHoverColor;

    #{$root}__button {
      color: $tagHoverColor;
    }
  }

  &:visited {
    color: $tagColor;
  }

  &--inactive {
    border: 0;

    &:hover {
      cursor: default;
      border: 0;
    }
  }

  &--button {
    padding-right: $tagPaddingRight;
  }

  &--button-left {
    padding-left: $tagPaddingRight;

    #{$root}__button {
      right: auto;
      left: 10px;
    }
  }

  &__button {
    transition: color .2s;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    &::before {
      font-size: 10px;
    }

    &--icon {
      &_close {
        color: $tagCloseColor;

        @include iconFont(delete);
      }

      &_search {
        @include iconFont(search);
      }
    }
  }
}
