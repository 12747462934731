.loader {
  @include spinner();
  position: relative;
  background: $loaderBackgroundColor;

  picture & {
    height: 100%;
    min-height: 120px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.loading {
  min-height: 60px;
  margin: 30px 0;
  position: relative;
  overflow: hidden;

  @include iconFont(loading, 'before') {
    font-size: 26px;
    display: block;
    position: absolute;
    animation: spin 1.5s infinite linear;
    color: $loaderColor;
    top: -300px;
    left: -300px;
    margin: auto;
    right: -300px;
    bottom: -300px;
    width: 26px;
    height: 26px;
  }
}
