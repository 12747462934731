.lightbox {
  display: block;
  position: fixed;
  z-index: $indexLightbox;
  top: 40px;
  width: 100%;
  max-width: 740px;
  left: 50%;
  transform: translateX(-50%);
  background: $whiteColor;
  border-radius: $lightBoxBorderRadius;

  &--wide {
    max-width: 1200px;
  }

  .native-app & {
    top: 80px;
  }

  @media (min-width: $screen-mobile-large) {
    width: 95%;
  }

  &__container {
    $scrollBarMargin: 4px;

    @include customScrollbar();
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 70vh;
    margin: 40px 16px 16px;
    padding-bottom: 2px;

    @media (min-width: $screen-tablet-portrait) {
      margin: 40px #{32 - $scrollBarMargin} 32px;
    }

    @media (min-width: $screen-laptop) {
      margin: 64px #{40 - $scrollBarMargin} 40px;
    }

    & > * {
      margin: 0 $scrollBarMargin !important;
    }

    .grid {
      padding-right: 32px;
    }
  }

  &__buttons {
    margin: 20px;
    display: flex;
    justify-content: space-between;
  }

  &__close-btn {
    @include iconFont(close);
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    padding: 16px;
    cursor: pointer;
    color: $lightboxCloseBtnColor;
  }

  &__loader {
    width: 25px;
    height: 25px;
  }
}

// PLACEHOLDER
/* stylelint-disable-next-line */
lightbox {
  display: none;
}
