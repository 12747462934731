.scroll-animated-teaser {
  position: relative;
  margin: 24px 0;

  --center-line: 50%;
  --gutter: 8px;

  @media (min-width: $screen-tablet-sm) {
    --center-line: 42.5%;
    --gutter: 24px;
  }

  @media (min-width: $screen-tablet-portrait) {
    --gutter: 40px;
  }

  &__item {
    & > * {
      opacity: 0;
      transform: translateY(40px);
      transition: all 1s ease-out;
      display: block;
    }

    &.on-screen--visible > * {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__item--n0 {
    width: calc(var(--center-line) - var(--gutter));
  }

  &__item--n1 {
    margin-top: 3%;
    width: calc(var(--center-line) - var(--gutter));
  }

  &__item--n2 {
    --test: calc(100 - var(--center-line));
    position: absolute;
    right: 0;
    top: 6%;
    width: calc(var(--center-line) - var(--gutter));

    @media (min-width: $screen-mobile-large) {
      top: 7%;
    }

    @media (min-width: $screen-tablet-sm) {
      top: 5%;
      width: calc(62% - var(--gutter));
    }

    @media (min-width: $screen-laptop) {
      top: 7%;
      width: calc(59% - var(--gutter));
    }
  }

  &__item--n3 {
    margin: 4% 0 0;

    @media (min-width: $screen-tablet-sm) {
      margin: 14% 20% 0 10%;
    }
  }

  &__image {
    width: 100%;
    border-radius: $scrollAnimatedTeaserBorderRadius;
  }

  &__text {
    @include fontSize($fontSizeSmall);
    margin: 4px 0;
    line-height: 1.3;
  }
}
