@import '../../dependencies.scss';

.countdown-teaser {
  $root: &;
  position: relative;
  text-align: center;
  padding: 1px 0;
  margin: 0;
  overflow: hidden;
  min-height: 220px;

  @media (min-width: $screen-tablet-portrait) {
    display: flex;
    justify-content: center;
  }

  @media (min-width: $screen-desktop) {
    margin: 0 auto;
    max-width: $maxWidthOuter;

    .row--default & {
      margin: 0 auto 40px;
    }
  }

  &__wrapper {
    @media (min-width: $screen-tablet-portrait) {
      width: 100%;
      padding: 0 20px;
      display: flex;
      justify-content: center;
    }
  }

  &__img-wrapper {
    @media (max-width: $screen-mobile-landscape-max) {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -1000px;
      bottom: -1000px;
    }

    @media (min-width: $screen-tablet-portrait) {
      position: relative;
    }
  }

  &__img {
    width: auto;

    @media (min-width: $screen-tablet-portrait) {
      width: 100%;
    }
  }

  &__box-wrapper {
    position: relative;
    min-height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;

    @media (min-width: $screen-tablet-portrait) {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
    }
  }

  &__box {
    position: relative;
    margin: 20px 0;
    min-height: 220px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;

    @media (min-width: $screen-tablet-landscape) {
      margin: 40px 0;
      min-height: 220px;
    }
  }

  &__headline {
    @include fontSize(48px);
    color: $whiteColor;
    font-weight: $fontWeightBold;
    margin: 0 auto 20px;

    @media (min-width: $screen-tablet-landscape) {
      margin: 0 auto 40px;
    }

    h1,
    h2,
    h3,
    h4 {
      @include fontSize(32px);
      margin: 0;
    }

    > div,
    > p {
      @include fontSize(22px);
      font-weight: $fontWeightRegular;
      color: $whiteColor;
      margin: 0;
      padding: 16px 0 0;
    }
  }

  &__subline {
    @include fontSize(22px);

    a,
    p {
      margin: 0;
      color: inherit;
    }
  }

  &__countdown {
    display: flex;
    justify-content: center;
    margin: 0;
  }

  &__block {
    position: relative;
    margin: 0 6px;
    border: 1px solid rgb(255 255 255 / .5);
    background: rgb(0 0 0 / .5);
    width: 55px;
    padding: 5px 5px 10px;
    font-family: $fontPrimaryFamily;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: $screen-tablet-portrait) {
      padding: 10px 5px 15px;
      margin: 0 20px;
      width: 85px;
    }

    @media (min-width: $screen-tablet-landscape) {
      margin: 0 40px;
      width: 115px;
    }
  }

  &__number {
    font-size: 32px;
    font-weight: bold;
    color: $whiteColor;
    text-shadow: 0 2px 3px $grayDarkColor;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 52px;
    }

    @media (min-width: $screen-tablet-landscape) {
      font-size: 72px;

      .hero-slider & {
        font-size: 80px;
      }
    }
  }

  &__label {
    font-size: 10px;
    color: $whiteColor;
    margin: 6px 0 0;
    text-transform: uppercase;
    text-shadow: 0 2px 3px $grayDarkColor;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 16px;
    }

    @media (min-width: $screen-tablet-landscape) {
      font-size: 18px;

      .hero-slider & {
        font-size: 20px;
      }
    }
  }

  &__btn-wrapper {
    margin: 20px auto 0;

    @media (min-width: $screen-tablet-landscape) {
      margin: 40px auto 0;
    }
  }

  &__button {
    background: rgb(0 0 0 / .5);
    border: 1px solid $whiteColor;

    &:hover {
      background: rgb(0 0 0 / .5);
      border: 1px solid $whiteColor;
      box-shadow: 0 0 0 1px $whiteColor inset;
    }
  }
}
