.service-teaser {
  position: relative;
  text-decoration: none;
  text-align: center;

  &:hover,
  &:visited {
    text-decoration: none;
  }

  &::before {
    font-size: 26px;
    color: $serviceIconColor;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 30px;
    }

    @media (min-width: $screen-desktop-sm) {
      font-size: 40px;
    }
  }

  &__headline {
    margin: 10px 0 3px;
    color: $serviceHeadlineColor;
    font-weight: $fontWeightSemiBold;

    @include fontSize($serviceHeadlineFontSize);
  }

  &__subline {
    color: $serviceSublineColor;
    line-height: 1.2;
    font-weight: $fontWeightRegular;

    @include fontSize($serviceSublineFontSize);
  }

  &__button {
    margin: 5px 10px;

    @media (min-width: $screen-tablet-portrait) {
      margin: 10px 20px;
    }

    .button {
      min-width: auto;
      width: 100%;
      padding: 0 10px;
    }
  }
}
