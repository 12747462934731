@import "../../../bps/components/userShortcut/userShortcut.scss";

.user-shortcut {
  &__link {
    padding: 32px 5px 0;
    min-width: 48px;

    @media (min-width: $screen-mobile-sm) {
      padding: 22px 5px 0;
    }

    @media (min-width: $screen-mobile) {
      padding: 22px 5px 0;
    }

    @media (min-width: $screen-tablet-portrait) {
      padding: 12px 5px 0;
    }

    &::before {
      font-size: 26px;

      @media (min-width: $screen-desktop-sm) {
        font-size: 29px;
      }

      .header__sticky--active & {
        @media (min-width: $screen-desktop-sm) {
          font-size: 29px;
        }
      }
    }

    @media (min-width: $screen-desktop-sm) {
      padding: 10px;
    }

    &--wishlist {
      @include iconFont(heartUnfilled, before);

      @include iconFont(heartFilled, after) {
        color: $productBoxWishlistColor;
        position: absolute;
        font-size: 27px;
        top: 8px;
        display: none;
        left: 0;
        right: 0;
      }

      .user-shortcut__notification-badge--active & {
        @include iconFont(heartFilled, before);
      }

      &::after {
        .user-shortcut__notification-badge--active & {
          font-size: 27px;
        }
      }
    }

    &--login {
      @include iconFont(user, before);

      &.user-shortcut__link--active {
        @include iconFont(checked, after);

        &::before {
          color: $loggedInIconColor;
          margin: 0;
          font-size: 26px;

          @media (min-width: $screen-tablet-portrait) {
            font-size: 26px;
            margin: 0;
          }

          @media (min-width: $screen-desktop-sm) {
            font-size: 29px;
          }
        }

        &::after {
          position: absolute;
          top: 30px;
          left: 54%;
          font-weight: $fontWeightBold;
          color: $goldColor;

          @media (min-width: $screen-tablet-portrait) {
            left: 51%;
            top: 28px;
          }
        }
      }
    }
  }

  &__label {
    @media (min-width: $screen-tablet-portrait) {
      min-height: 22px;
      display: none;
    }
  }

  &__notification-badge-wrapper {
    position: absolute;
    left: 55%;
    margin: auto;
    top: auto;
    bottom: 12px;

    @media (min-width: $screen-tablet-portrait) {
      left: 55%;
    }

    @media (min-width: $screen-desktop) {
      bottom: 16px;
    }
  }

  &__notification-badge {
    font-size: 10px;
    font-weight: $fontWeightRegular;
  }
}
