.counter {
  $root: &;
  display: inline-block;
  width: $buttonCounterWidthMobile;
  height: $buttonCounterWidthMobile;
  position: relative;
  z-index: 10;
  background: $buttonCounterBackgroundColor;
  border: 1px solid $buttonCounterBorderColor;
  border-radius: $buttonCounterBorderRadius;

  @media (min-width: $screen-desktop) {
    width: $buttonCounterWidth;
    height: $buttonCounterWidth;
  }

  &--disabled {
    border: 0;
    cursor: not-allowed;

    #{$root}--active {
      box-shadow: none !important;
    }
  }

  &--active {
    z-index: 20;

    #{$root}__input {
      z-index: 620;
    }

    #{$root}__button {
      z-index: 610;
      opacity: 1;
      width: calc(100% + 8px);
      left: -4px;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        height: 22px;
        width: 100%;
        background-color: inherit;
      }

      &--increase {
        transform: translateY(-100%);

        &::after {
          bottom: $buttonCounterShowButtonPosition;
        }
      }

      &--decrease {
        transform: translateY(100%);

        &::after {
          top: $buttonCounterShowButtonPosition;
        }
      }
    }
  }

  &__input {
    width: 100%;
    height: 100%;
    text-align: center;
    background: $buttonCounterBackgroundColor;
    outline: none;
    font-size: $baseFontSize;
    font-weight: $counterInputFontWeight;
    position: relative;
    z-index: 5;
    border: 0 none;
    border-radius: $buttonCounterBorderRadius;

    &:focus,
    &:active {
      outline: none;
    }

    &--disabled {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $textBaseColor;
      font-size: 18px;
      cursor: not-allowed;
    }

    &[disabled] {
      color: $textBaseColor;
      -webkit-text-fill-color: $textBaseColor;
      background: $backgroundWhiteColor;
      border: 0;
      font-size: 18px;
    }
  }

  &__button {
    position: absolute;
    left: 0;
    height: 30px;
    width: 100%;
    cursor: pointer;
    transition: transform .1s ease-out, box-shadow .1s ease-out, opacity .1s ease-out, background .2s ease-out;
    background: $buttonCounterBorderColor;
    border: 0 none;
    color: $buttonCounterBackgroundColor;
    outline: none !important;
    line-height: 30px;
    font-size: 10px;
    opacity: 0;

    &:hover {
      background: $buttonCounterHoverBorderColor;
    }

    &--increase {
      @include iconFont(arrowUpBold) {
        transform: scaleY(.85);
        line-height: $buttonCounterLineHeightIncreaseButton;
      }
      top: $buttonCounterHideButtonPosition;
    }

    &--decrease {
      @include iconFont(arrowUpBold) {
        transform: scaleY(-.85);
        line-height: $buttonCounterLineHeightDecreaseButton;
      }
      bottom: $buttonCounterHideButtonPosition;
    }

    &--disabled {
      cursor: not-allowed;
      background: $buttonDisabledBackgroundColor;
      border-color: $buttonDisabledBorderColor;
      color: $buttonDisabledTextColor;

      &:hover {
        background: $buttonDisabledBackgroundColor;
      }
    }
  }
}
