.visual-teaser {
  --visual-teaser-bg: #ddd;
  --visual-teaser-text-color: #000;
  --visual-teaser-text-highlight-color: #f00;
  --visual-teaser-font-size: 22px;
  --visual-teaser-border-radius: 10px;
  --visual-teaser-description-direction: row;
  --visual-teaser-description-font-weight: 500;
  --visual-teaser-description-space: 80px;
  --visual-teaser-description-text-max-width: 340px;
  --visual-teaser-description-image-width: 56%;
  --visual-teaser-card-text-max-width: 750px;

  &__wrapper {
    display: flex;
    font-size: calc(var(--visual-teaser-font-size) * .8);
    line-height: 1.4;
    background: var(--visual-teaser-bg);
    color: var(--visual-teaser-text-color);
    border-radius: var(--visual-teaser-border-radius);
    overflow: hidden;

    @media (min-width: $screen-mobile-large) {
      font-size: var(--visual-teaser-font-size);
    }
  }

  &__description {
    @include spinner();
    width: 100%;
    position: relative;
    min-height: 320px;
  }

  &__intro,
  &__image,
  &__checkbox,
  &__card {
    display: none;
  }

  h2,
  h3,
  &__head {
    font-family: $fontPrimaryFamily;
    font-weight: $fontWeightRegular;
    font-size: calc(var(--visual-teaser-font-size) * 1.4);
    line-height: 1.1;
    text-align: center;
    margin: 0 0 20px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: calc(var(--visual-teaser-font-size) * 1.8);
      margin: 0 0 40px;
    }
  }
}
