@import "../../../bps/components/breadcrumbs/breadcrumbs.scss";

.breadcrumbs {
  margin: 16px auto 14px;

  &__link-name {
    line-height: 1.4;
  }

  &__item {
    .page--landing-page & {
      display: none !important;
    }
  }
}
