.add-to-cart-layer {
  $root: &;

  @include container();
  position: relative;
  display: block;

  &--small {
    @media (min-width: $screen-tablet-portrait) {
      display: none;
    }
  }

  &__overlay {
    background: rgb(0 0 0 / .5);
    position: fixed;
    z-index: $indexHeader - 1;
    top: 60px;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__box {
    position: absolute;
    z-index: $indexHeader + 1;
    width: 100%;
    background: $whiteColor;
    padding: 15px;
    box-shadow: 0 0 20px 0 rgb(47 47 45 / .2);
    left: 0;

    @media (min-width: $screen-tablet-portrait) {
      top: $addToCartLayerTopTablet;
      right: $addToCartLayerRightTablet;
      width: 350px;
      left: auto;
    }

    @media (min-width: $screen-desktop-sm) {
      top: $addToCartLayerTopDesktop;
      right: $addToCartLayerRightDesktop;

      .header__sticky--active & {
        top: $addToCartLayerTopTablet;
        right: $addToCartLayerRightDesktop;
      }
    }
  }

  &__triangle {
    $size: 8px;
    width: 0;
    height: 0;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $whiteColor;
    position: absolute;
    top: -$size;
    right: 26px;

    @media (min-width: $screen-tablet-portrait) {
      right: 24px;
    }
  }

  &__header {
    display: flex;
    border-bottom: $borderLightColor 1px solid;
    padding-bottom: 12px;
  }

  &__headline {
    font-size: 13px;
    font-weight: $fontWeightBold;
    color: $addToCartPrimaryColor;
    letter-spacing: $addToCartHeadlineLetterSpacing;
    text-transform: $addToCartHeadlineTextTransform;
    flex-grow: 1;
  }

  &__close {
    color: $addToCartPrimaryColor;
    padding: 8px;
    margin: -11px -8px;

    @include iconFont(delete) {
      font-size: 8px;
    }
  }

  &__message {
    color: $successColor;
    padding: 20px 0 24px;
    font-size: $fontSizeSmall;
    font-weight: $fontWeightBold;

    @include iconFont(checkSmall, before) {
      $size: 40px;
      font-size: 9px;
      border-radius: 50%;
      background: $successColor;
      color: $whiteColor;
      width: $size;
      height: $size;
      line-height: $size;
      margin-right: 16px;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
    }
  }

  &__cart-btn {
    float: left;
    min-width: 180px;

    @media (min-width: $screen-tablet-portrait) {
      width: 100%;
    }

    #{$root}--small & {
      margin-bottom: 16px;
    }
  }

  &__continue-link {
    font-family: inherit;
    transition: background .15s linear, border-color .15s linear, color .15s linear;
    cursor: pointer;
    line-height: $buttonPrimaryHeightMobile;
    text-align: center;
    position: relative;
    border: $buttonBorderWidth solid $buttonPrimaryBorderColor;
    border-radius: $buttonBorderRadius;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 1.5px;
    background: $buttonPrimaryBackgroundColor;
    color: $buttonPrimaryTextColor;
    display: inline-block;
    width: auto;
    font-weight: $fontWeightBold;
    text-transform: uppercase;
    padding: 0 16px;
    font-size: $buttonFontSizeMobile;
    float: left;
    min-width: 180px;
    clear: both;

    @media (min-width: $screen-mobile-landscape) {
      min-width: 200px;
      padding: 0 32px;
    }

    @media (min-width: $screen-tablet-portrait) {
      font-size: $buttonFontSizeDesktop;
      width: 100%;
    }

    &--hover,
    &:hover {
      background: $buttonPrimaryHoverBackgroundColor;
      color: $buttonPrimaryHoverTextColor;
    }
  }

  &__list-container {
    @include customScrollbar(4px, $grayLighterColor);
    overflow: hidden auto;
    max-height: 215px;
    margin: 16px 0;
  }

  &__list {
    padding: 8px;
  }

  &__product:not(:last-child) {
    padding-bottom: 16px;
    border-bottom: $borderLightColor 1px solid;
    margin-bottom: 16px;
  }

  &__footer {
    border-top: $borderLightColor 1px solid;
    padding-top: 16px;
    font-size: $fontSizeSmall;
    line-height: 130%;
  }

  &__cols {
    display: flex;
    margin-bottom: 4px;
  }

  &__col {
    width: 50%;

    &:last-child {
      text-align: right;
      font-weight: $fontWeightSemiBold;
    }

    &--strong {
      color: $successColor;
    }
  }

  &__grand-total {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: $borderLightColor 1px solid;
  }

  &__upsell-promotion {
    margin-bottom: 16px;

    span,
    strong {
      background: $successColor;
      color: $whiteColor;
      font-weight: $fontWeightSemiBold;
      border-radius: 2px;
      padding: 2px 8px;
    }
  }

  &__tax {
    color: $grayColor;
    font-size: $fontSizeXXSmall;
  }
}
