.personalization {
  $root: &;

  &__banner {
    background: $redDarkColor;
    padding: 8px;
    display: flex;
    position: relative;
    min-height: 40px;
    text-decoration: none;

    &--type_rank2,
    &--type_rank5,
    &--type_rank6 {
      #{$root}__banner-icon {
        display: none;
      }
    }

    + .product-box-wide__product-flags {
      top: 54px;
    }
  }

  &__banner-icon {
    margin: -16px 8px -5px 0;
    background: url('../staticImages/heart-finger-sm.png') center center no-repeat, $redDarkColor;
    background-size: contain;
    border-radius: 50%;
    width: 60px;
    height: 45px;
    position: absolute;

    &--lg {
      margin: -24px 8px -5px;
      height: 55px;
    }
  }

  &__banner-content {
    color: $personalizationColor;
    text-align: center;
    font-size: 1rem;
    font-weight: $fontWeightBold;
    margin: auto;
    flex: 1 1;
    position: relative;
    z-index: 3;
    text-shadow: 0 0 3px $redDarkColor;
    padding-left: 8px;
  }
}
