.product-box {
  $root: &;
  background: $productBoxBackgroundColor;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: $productBoxBorder;

  &:hover {
    z-index: 2;
  }

  &--error {
    $stripeColor: transparentize($errorColor, .8);
    background-image: linear-gradient(45deg, $stripeColor 25%, #fff 25%, #fff 50%, $stripeColor 50%, $stripeColor 75%, #fff 75%, #fff 100%); // stylelint-disable-line  plugin/no-unsupported-browser-features
    background-size: 56.57px 56.57px;
  }

  &__segment-header {
    display: flex;
    border-bottom: $grayLightColor 1px solid;
    padding-bottom: 12px;
    text-decoration: none;
    min-height: 75px;
  }

  &__header-text {
    width: 100%;
    padding-right: 8px;
  }

  &__body {
    display: flex;
    flex-grow: 1;

    #{$root}--type-horizontal & {
      flex-direction: column;
    }

    #{$root}--type-vertical & {
      flex-direction: row;
    }
  }

  &__rating-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0 14px;
  }

  &__segment-main {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    #{$root}--type-horizontal & {
      text-align: left;
    }

    #{$root}--type-vertical & {
      text-align: right;
      flex: 1 1;
      padding-top: 8px;

      @media (min-width: $screen-desktop) {
        padding-top: 16px;
      }
    }
  }

  &__segment-image {
    display: flex;

    #{$root}--type-vertical & {
      flex: 1 1;

      @media (min-width: $screen-desktop) {
        max-width: 160px;
      }
    }

    #{$root}--type-horizontal & {
      flex-grow: 1;
      flex-direction: column;
      margin-bottom: 24px;
      display: flex;
    }
  }

  &__image-container {
    width: 100%;
    position: relative;
    margin-top: auto;

    #{$root}--type-vertical & {
      padding: 16px 0 0;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      .image-element,
      .product-box__image-wrapper .lazy-load {
        min-width: 100px;
        min-height: 270px;
        margin: 0;
      }
    }

    #{$root}--type-horizontal & {
      .image-element,
      .product-box__image-wrapper .lazy-load {
        min-height: 160px;

        @media (min-width: $screen-tablet-xsm) {
          min-width: 100%;
        }
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;

    @media (min-width: $screen-mobile-landscape) {
      display: flex;
    }

    > .image-element__pic {
      @media (min-width: $screen-mobile-landscape) {
        display: flex;

        #{$root}--type-horizontal & {
          width: 100%;
        }
      }
    }

    img {
      margin: auto auto 0;

      #{$root}--type-horizontal & {
        width: 100%;
      }

      #{$root}--type-vertical & {
        width: 100px;
      }
    }
  }

  &__image-wrapper {
    #{$root}--type-horizontal & {
      .is_safari & {
        min-height: 136px;
        max-height: 100%;
      }
    }

    #{$root}--type-vertical & {
      .is_safari & {
        min-height: 270px;
        max-height: 100%;
      }
    }
  }

  &__vintage,
  &__name,
  &__short-description {
    padding: 0;
    max-width: 100%;
    width: 100%;
    display: inline-block;
    text-decoration: none;
    line-height: 110%;
    font-size: 13px;
    color: $productBoxNameColor;
  }

  &__name {
    width: 100%;
    font-weight: $fontWeightSemiBold;
    margin-bottom: 4px;
  }

  &__short-description {
    color: $productBoxShortDescriptionColor;
  }

  &__product-flags {
    position: absolute;
    z-index: 1;
    min-width: 45px;
    height: 87%;
    text-decoration: none;

    #{$root}--type-vertical & {
      top: 24px;
      left: 0;
    }
  }

  &__basket-button {
    width: 100%;
    min-width: 100%;
    align-self: flex-end;
    float: left;
    padding: 0 5px;
  }

  &__button--to-product {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__price-wrapper {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;

    #{$root}--type-horizontal & {
      flex-direction: row;
    }

    #{$root}--type-vertical & {
      flex-direction: column;
    }

    &--no-price {
      flex-grow: 1;
      justify-content: flex-end;
    }
  }

  &__price {
    font-size: $fontSizeXXSmall;

    #{$root}--type-horizontal & {
      flex: 1 1;
      padding-right: 4px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    #{$root}--type-vertical & {
      margin: 8px 0;
    }

    .price-info {
      font-size: $fontSizeXXSmall;

      &__current {
        font-size: $fontSizeXXMedium;
      }

      &__currency {
        margin-left: -4px;
      }

      &__old {
        color: $grayColor;
        margin-bottom: 2px;
      }

      &__unit {
        color: $grayColor;
      }

      &__asterisk {
        font-size: .5em;
        top: -.4em;
        left: -.1em;
      }
    }
  }

  &__availability {
    #{$root}--type-horizontal & {
      margin-bottom: 4px;
      margin-top: -8px;

      .availability-info__label {
        text-align: left;
      }
    }

    #{$root}--type-vertical & {
      margin: 8px 0 0;
      width: 100%;
    }

    .availability-info--label {
      font-size: $fontSizeXSmall;
      text-transform: none;
      word-break: break-word;

      &::before {
        width: 6px;
        height: 6px;
        margin: 0 3px 0 0;
      }
    }
  }

  &__link {
    color: $productBoxLinkColor;
    font-size: $productBoxLinkFontSize;
    line-height: 1;
    display: inline-block;
    text-decoration: underline;
    text-decoration-color: lighten($productBoxLinkColor, 20%);
    margin-top: 8px;

    #{$root}--type-horizontal & {
      text-align: right;
    }
  }

  &__area {
    font-size: $fontSizeXMedium;
    margin-bottom: 4px;
    line-height: 120%;
    width: 100%;
  }

  &__ratings {
    justify-content: normal;
    font-size: 13px;

    #{$root}--type-horizontal & {
      margin: 0;
    }

    #{$root}--type-vertical & {
      margin-bottom: 8px;
    }
  }

  &__bottle-amount {
    font-size: $fontSizeSmall;
  }

  &__counter {
    max-width: 135px;
    margin-right: 6px;
    min-width: 42px;
    width: 42px;
    height: 42px;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    &--currently-free {
      height: 0;

      &-reorder {
        @media (min-width: $screen-desktop) {
          height: 44px;
          min-width: 1px;
        }
      }

      &-product-box {
        @media (min-width: $screen-tablet-xsm) {
          height: 44px;
          min-width: 1px;
        }
      }
    }
  }

  &__button-to-cart {
    position: relative;
    min-width: auto;
    height: 42px;
    width: 42px;
    padding: 0;

    &::after {
      font-size: 20px;
      transform: translate(1px, 6px);
    }
  }

  &__button-wishlist {
    position: relative;
    width: 24px;
    height: 24px;
    text-decoration: none;
    cursor: pointer;
    font-size: 20px;
    color: $productBoxButtonWishlistColor;

    @include iconFont(heartUnfilled) {
      transition: color .15s linear;
    }

    &:hover {
      @include iconFont(heartFilled);
    }

    &--filled {
      @include iconFont(heartFilled);
      color: $productBoxWishlistColor;
    }

    &--remove {
      width: 16px;
      height: 16px;

      @include iconFont(close) {
        color: $grayDarkColor;
        font-size: 16px;
        height: 16px;
        text-align: center;
      }

      &:hover {
        @include iconFont(close) {
          color: $grayDarkColor;
        }
      }
    }

    &--disabled {
      cursor: not-allowed;
      color: $productBoxButtonWishlistColor;

      &:hover {
        @include iconFont(heartUnfilled);
      }
    }

    &--loading {
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        cursor: not-allowed;
        z-index: 10;
        position: absolute;
        left: 0;
      }

      &::after {
        content: map-get($icons, 'loading') !important;
        color: $productBoxLoadingColor;
        cursor: not-allowed; /* stylelint-disable-line */
        z-index: 20;
        position: absolute;
        animation: spin 4s linear infinite;
        font-size: 26px !important;
        width: 26px;
        font-weight: 100 !important;
        margin: auto;
        padding: 0 !important;
        line-height: 26px !important;
        height: 26px;
        top: -300px;
        right: -300px;
        bottom: -300px;
        left: -300px;
      }

      &:hover {
        &::before {
          content: "";
        }
      }
    }
  }

  &__tags {
    margin-top: 8px;
    margin-bottom: 16px;
    position: relative;

    .tags {
      &__list {
        float: none;
        width: 100%;
      }

      &__tag {
        font-size: $fontSizeXXSmall;
        padding: 2px 8px;
        margin: 0 0 4px;
        border: 0;

        &:hover {
          border: 0;
        }
      }
    }

    .tag {
      &--subscription {
        background: $brandPrimaryColor;
        color: $whiteColor;
        font-weight: $fontWeightRegular;
        cursor: pointer;

        &:visited {
          color: $whiteColor;
        }

        &:hover {
          text-decoration: underline;
          background: $brandPrimaryColor;
          color: $whiteColor;
        }
      }
    }
  }

  &__conversion {
    width: 100%;

    #{$root}--type-vertical & {
      display: flex;
      align-items: flex-end;
      flex-grow: 1;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-content: flex-end;
    }
  }

  &__status {
    color: $whiteColor;
    padding: $productBoxStatusPadding;
    font-size: 11px;
    text-align: center;
    text-decoration: none;
    border-radius: $productBoxStatusBorderRadius;
    margin-left: 2px;

    &--unavailable {
      background: $errorColor;
      align-self: center;
      text-transform: $productBoxUnavailableTextTransform;
      font-weight: $fontWeightSemiBold;
      font-size: $fontSizeSmall;
      letter-spacing: 1px;
    }

    &--on-request {
      background: $brandPrimaryColor;
      align-self: center;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: $fontWeightSemiBold;
      font-size: $fontSizeSmall;
      letter-spacing: 1px;
    }

    &--basket {
      @include iconFont(checkSmall) {
        font-size: .7em;
        margin-right: 8px;
      }
      background: $successColor;
      animation: growBounce;
      animation-duration: .3s;
      display: inline-block;
      padding: 11px 12px;
      margin-top: 8px;
    }

    &--basket-up {
      margin: -5px 0;
    }

    &--successor {
      @include iconFont(arrowRight, after) {
        font-size: 7px;
        display: inline-block;
        padding-left: 2px;
      }
      background: $productBoxStatusSuccessorBackgroundColor;
      color: $productBoxStatusSuccessorColor;

      @media (min-width: $screen-tablet-landscape) and (max-width: $screen-desktop) {
        white-space: nowrap;
        padding: 16px 4px;
      }
    }
  }

  &__placeholder {
    min-height: 361px;
    display: flex;
    flex-direction: column;

    &.product-box-wide {
      flex-direction: row;
    }

    .loader {
      width: 100%;
      position: absolute;
      height: 100%;
      top: 0;
      z-index: 1;
      left: 0;
      background: transparent;
    }

    .product-box__segment-image {
      width: 100%;
      position: relative;
    }

    .product-box__image {
      justify-content: center;
    }

    .product-box__image-container {
      padding: 16px 0 0;
      display: flex;
      align-items: flex-end;
    }

    .product-box-wide__segment-image {
      max-height: 272px;
    }

    .product-box-wide__long-description {
      max-height: 140px;
      overflow: hidden;
    }

    .lazy-load-image__image {
      min-width: 100px;
      min-height: 270px;
    }

    img {
      max-width: 100%;
    }
  }

  &__segment-review {
    background: $productReviewsBackground;
    color: $whiteColor;
    padding: 8px 10px 14px;
    margin: -10px -10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    &__headline {
      font-size: 14px;
      font-weight: $fontWeightSemiBold;
      color: $whiteColor;

      @media (min-width: $screen-tablet-landscape) {
        font-size: 18px;
      }
    }

    .ratings:not(.ratings--disabled) {
      font-size: 26px;
      padding: 0 0 5px;

      .ratings__star {
        &::before {
          opacity: 1;
          transform: scale(.8);
          color: $ratingStarsHoverColor;
        }

        &--empty::before {
          opacity: 1;
          transform: scale(.8);
          color: $ratingEmptyStarsColor;
        }

        &:hover {
          &::before {
            color: $ratingStarsHoverColor;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-tablet-portrait) and (max-width: 800px), (min-width: $screen-tablet-landscape) and (max-width: 1100px) {
  .products-list {
    .product-box--type-vertical {
      .product-box__image img {
        width: 80px;
        min-height: 175px;
      }

      .price-info__current {
        font-size: 24px;
      }
    }
  }
}
