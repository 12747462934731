.page-main {
  display: block;
  min-height: 140px;

  .default-layout & {
    padding: 1px 0 0;
    margin: 0;

    @media (min-width: $screen-tv) {
      margin: 0 auto;
      max-width: 2560px;
    }
  }
}
