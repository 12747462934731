.modal {
  &:not(.modal--inline) {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $backgroundWhiteColor;
    width: 100%;
    max-width: calc(100vw - 30px);
    max-height: calc(100vh - 30px);
    z-index: $indexModal;
    padding: 15px;
  }

  @media (min-width: $screen-tablet-portrait) {
    width: 800px;
    max-width: calc(100vw - 40px);
    max-height: calc(100vh - 40px);
  }

  &--inline {
    display: block;
    position: relative;
    background: $backgroundWhiteColor;
    padding: 16px;
    text-align: center;
    color: $brandPrimaryColor;
    font-size: $fontSizeLarge;
    width: 100%;
    margin: 0 0 24px;
  }

  &--inline-top {
    margin: auto;
    position: relative;
    padding: 8px 16px;
    max-width: $maxWidthOuter;
  }

  &--success {
    background: $successColor;
    color: $textLightColor;
  }

  &--warning {
    background: $warningColor;
    color: $textLightColor;
  }

  &--error {
    background: $errorColor;
    color: $textLightColor;
  }

  &__text-big {
    padding: 10px 20px;
    font-size: $fontSizeXXXMedium;
    margin: 0;
  }

  &__close-button {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 2px;
    right: 2px;
    margin: auto;
    cursor: pointer;

    @include iconFont('close') {
      font-size: 10px;
      color: inherit;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media (min-width: $screen-tablet-portrait) {
      &::before {
        font-size: 12px;
      }
    }
  }
}
