.tabs-component {
  width: 100%;

  &-tab {
    color: $grayMediumColor;
    font-size: $fontSizeMedium;
    font-weight: $fontWeightSemiBold;
    margin-right: 0;
    list-style: none;

    &:hover {
      color: $brandSecondaryColor;
    }

    &.is-active {
      color: $brandHighlightColor;

      @media (min-width: $screen-tablet-portrait) {
        transform: translateY(0);
      }
    }

    &.is-disabled * {
      color: $buttonDisabledTextColor;
      cursor: not-allowed !important;
    }
  }

  &-tabs {
    margin-bottom: 5px;
    padding: 0;

    @media (min-width: $screen-tablet-portrait) {
      align-items: stretch;
      display: flex;
      justify-content: flex-start;
    }
  }

  &-tab-a {
    align-items: center;
    color: inherit;
    display: flex;
    padding: 8px 0;
    margin: 0 24px 8px 0;
    text-decoration: none;

    @include iconFont('arrowRight');

    &::before {
      font-size: 70%;
      padding-right: 5px;
    }
  }

  &-panels {
    padding: 5px 0 0;
  }
}
