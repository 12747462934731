@import '../../../bps/components/wishlistEdit/wishlistEdit.scss';

.wishlist-edit {
  $root: &;

  &__list-head {
    position: relative;
    cursor: pointer;

    @media (min-width: $screen-tablet-portrait) {
      border-bottom: $textDarkestColor 1px solid;
      display: flex;
      padding: 5px 0;
    }
  }

  &__list-name {
    @include fontSize($fontSizeFont);
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px 0;
    margin-bottom: 8px;
    border-bottom: $textDarkestColor 1px solid;
    position: relative;
    font-weight: $fontWeightRegular;

    @include iconFont(arrowDown) {
      transform: scaleY(1);
      font-weight: $fontWeightRegular;
    }

    @media (min-width: $screen-desktop) {
      padding: 8px 0;
    }

    @media (min-width: $screen-tablet-portrait) {
      flex-grow: 1;
      border-bottom: 0;
      margin: 0;
    }

    &::before {
      transition: transform .2s;
      color: $wishlistEditListNameArrowColor;
      margin-right: 16px;

      @media (min-width: $screen-desktop) {
        margin-right: 8px;
      }
    }

    #{$root}--default & {
      font-weight: $fontWeightSemiBold;
    }

    #{$root}__list--open &:before {
      transform: scaleY(-1);
    }

    #{$root}__list-head--new & {
      font-weight: $fontWeightSemiBold;

      @include iconFont(plus) {
        font-weight: $fontWeightRegular;
        font-size: 18px;
      }
    }
  }

  &__select {
    margin-top: -1px;
    height: 50px;

    &--empty {
      visibility: hidden;
    }

    .select__selected-value {
      padding-top: 0;
    }

    .select__wrapper {
      border: 0 none;
      color: $whiteColor;
      background: $goldColor;

      &::before,
      .select__selected-value {
        color: $whiteColor;
      }

      .select__custom-option {
        color: $textDarkestColor;

        &:hover {
          color: $whiteColor;
        }
      }
    }

    .select__custom-option:first-child {
      display: none;
    }
  }

  .button--primary-small,
  .button--secondary-small {
    height: 45px;
    line-height: 26px;

    @media (min-width: $screen-desktop) {
      height: 32px;
    }
  }

  &__name-input {
    height: 45px;

    input {
      @include fontSize($baseFontSize);
    }

    @media (min-width: $screen-desktop) {
      height: 35px;
    }
  }
}
