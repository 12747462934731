.go-to-url-select {
  color: $paginationTextColor;
  font-size: $fontSizeSmall;
  margin: 16px 0;

  @media (min-width: $screen-tablet-landscape) {
    font-size: $fontSizeXMedium;
  }

  &__pages {
    display: flex;
    flex-grow: 3;
    margin: 0;
    flex-flow: column wrap;

    @media (min-width: $screen-tablet-portrait) {
      align-items: center;
      justify-content: flex-start;
      flex-flow: row nowrap;
    }
  }

  &__wrapper {
    position: relative;

    &--disabled {
      &::before {
        content: '';
      }
    }
  }

  &__label {
    font-size: $fontSizeSmall;
    line-height: 1.4;
    color: $grayColor;

    @media (min-width: $screen-tablet-landscape) {
      font-size: $fontSizeXMedium;
    }
  }

  .select--formless .select__custom-dropdown {
    @include customScrollbar(4px, $grayLighterColor);
    border-right: 2px solid $whiteColor;

    &--visible {
      border-bottom: 4px solid $whiteColor;
    }
  }

  .select.select--formless {
    margin-right: 0;
    color: $redDarkColor;

    .select__wrapper {
      height: auto;
      padding-top: 8px;

      @media (min-width: $screen-tablet-portrait) {
        height: 45px;
        padding-top: 0;
      }

      &::before {
        color: $redDarkColor;
        top: 66%;

        @media (min-width: $screen-tablet-portrait) {
          top: 50%;
          right: 0;
        }

        @media (min-width: $screen-tablet-landscape) {
          right: 16px;
        }
      }
    }

    .select__selected-value {
      font-weight: $fontWeightBold;
      font-size: 14px;
      padding: 0 16px 0 8px;

      @media (min-width: $screen-tablet-portrait) {
        padding: 0 0 0 24px;
      }

      @media (min-width: $screen-tablet-landscape) {
        font-size: $fontSizeXMedium;
        padding: 0 24px;
      }
    }

    .select__custom-option {
      font-size: $baseFontSize;

      &--selected {
        color: $grayColor;
        font-weight: $fontWeightRegular;
      }
    }
  }
}
