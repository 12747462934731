@import "../../../bps/components/addressSuggestion/addressSuggestion.scss";

.address-suggestion {
  @media (max-width: $screen-tablet-landscape) {
    &-container {
      max-height: 210px;
    }

    &-element {
      margin: 0 -20px;
      padding: 15px 20px;
    }
  }
}
