/* stylelint-disable-next-line */
content-cinema-teaser {
  @include container($maxWidthInner);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0 0;

  @media (min-width: $screen-tablet-portrait) {
    flex-direction: row;
    padding: 0;
  }

  div[slot="teaser-background"],
  div[slot="teaser-slider"] {
    display: none;
  }

  div[slot="teaser-content"] {
    @include componentPreloader() {
      height: auto;
    }
    min-height: 100px;
  }
}
