@import '../../../bps/components/addToCartLayer/addToCartLayer.scss';

.add-to-cart-layer {
  $root: &;
  max-width: unset;

  &__box {
    top: -62px;

    @media (min-width: $screen-tablet-portrait) {
      top: -68px;
      right: 4px;
      width: 350px;
      left: auto;
    }

    @media (min-width: $screen-desktop-sm) {
      top: -6px;
      right: 0;

      .header__sticky--active & {
        top: -6px;
        right: 0;
      }
    }
  }

  &__triangle {
    right: 19px;

    @media (min-width: $screen-tablet-portrait) {
      right: 26px;
    }
  }

  &__header,
  &__footer,
  &__grand-total {
    border-color: $blackColor;
  }

  &__product {
    border-color: $blackColor !important;
  }

  &__col:first-child,
  &__upsell-promotion {
    font-weight: $fontWeightMedium;
  }

  &__upsell-promotion {
    span,
    strong {
      background: $redDarkColor;
    }
  }

  &__col {
    &:last-child {
      font-weight: $fontWeightBold;
    }
  }

  .image-element {
    margin-top: 4px;
    display: block;
  }

  .add-to-cart-position {
    &__name {
      font-size: $fontSizeXSmall;
    }

    &__bottles {
      font-size: $fontSizeXSmall;
      font-weight: $fontWeightMedium;
    }

    &__delete-btn {
      font-size: $fontSizeXXSmall;
    }

    &__price {
      margin-top: 4px;

      .price-info {
        & > * {
          font-size: $fontSizeXSmall;
        }

        &__current {
          display: inline-flex;
          font-size: $fontSizeSmall;
        }

        &__value-cent {
          font-size: 1em;
          margin: 0;

          &::before {
            content: ',';
            margin: 0 .05em;
          }
        }

        &__unit {
          font-size: $fontSizeXXSmall;
          padding: 0;

          & > * {
            color: $grayColor;
          }
        }

        &__savedPercentage {
          display: none;
        }
      }
    }
  }

  &__headline {
    padding: 10px 0;
  }

  &__close {
    padding: 16px;

    @media (min-width: $screen-desktop) {
      padding: 8px;
    }
  }
}
