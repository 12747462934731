.product-box-slider {
  &__item {
    padding: 10px;
    min-width: 255px;
  }

  &__slider {
    .flags {
      margin: 10px 0 0;
    }

    .flag--new {
      top: -20px;
      left: 0;
    }

    .product-box__product-flags {
      top: 20px;
      left: 0;
    }
  }

  &--itemcount_2 {
    max-width: 800px;
    margin: 0 auto;
  }

  .carousel {
    &__slide--single {
      width: 100%;
      max-width: 490px;
      margin: auto;

      @media (min-width: $screen-tablet-portrait) {
        max-width: none;
      }
    }
  }
}

// PLACEHOLDER
/* stylelint-disable-next-line */
product-box-slider {
  .product-box {
    max-width: 25%;

    &:nth-child(n+5) {
      display: none;
    }
  }
}
