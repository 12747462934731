@import '../../../bps/components/productBoxWide/productBoxWide.scss';

.product-box-wide {
  $root: &;
  padding: 0;

  &__sub-name {
    font-weight: $fontWeightRegular;
  }

  &__segment-image {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__segment-center {
    flex: 1 1;
    margin: 16px 24px;
  }

  &__segment-conversion {
    margin: 16px 16px 16px 0;
    align-content: flex-end;
    justify-content: flex-start;

    .product-box {
      &__link {
        width: 100%;
      }
    }
  }

  &__product-flags {
    position: absolute;
    z-index: 1;
    top: 14px;
    left: 14px;
  }

  &__product-flags--conversion {
    top: auto;
    right: auto;
    left: auto;
    position: relative;
    align-self: flex-end;
    width: 100%;

    .flag--stoerer {
      background: transparent;
      border-radius: 0;
      width: auto;
      height: auto;
      line-height: 1.3;
      padding: 0;
      display: inline-block;
      font-size: 20px;
      font-weight: $fontWeightBold;
      color: $goldColor;
      text-transform: uppercase;
    }
  }

  &__long-description {
    color: $textDarkestColor;
    font-size: 16px;
  }

  &__price-wrapper {
    width: 100%;
    min-height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .product-box__ratings {
    flex-grow: 1;
    margin: 30px 0 12px;
  }

  &__status {
    margin: 0 0 12px;
  }

  &__image-wrapper {
    background: $grayLighterColor;
    background: $productBoxWideBottleBackground;
    padding: 20px;
  }

  .availability-info {
    font-size: 12px;
    margin: 6px 0;
  }

  .price-info {
    &__old {
      color: $textDarkestColor;
      text-align: left;
    }

    &__tax,
    &__unit {
      color: $textDarkestColor;
    }

    &__current {
      font-size: 34px;
      justify-content: flex-end;
    }

    &__currency {
      margin-left: 0;
    }

    &__asterisk {
      font-size: .5em;
      top: auto;
      left: auto;
    }
  }

  &__link {
    align-self: flex-end;
  }

  &__buttons {
    display: flex;
    justify-content: flex-start;
  }

  &__button-wishlist {
    top: -14px;
    right: -14px;
  }

  &__highlight-text {
    text-align: center;
    text-transform: uppercase;
    color: $whiteColor;
    font-weight: $fontWeightBold;
    font-size: $fontSizeMedium;
    position: absolute;
    top: -35px;
    left: 0;
    width: 100%;
  }

  .read-more__btn {
    font-weight: $fontWeightRegular;
    display: block;
  }

  .product-box__status--unavailable {
    .abo-select & {
      background: #bebebe !important;
    }
  }
}
