.accordion {
  $root: &;
  position: relative;

  &--open {
    > #{$root}__headline::after {
      transform: translateY(-50%) scale(1, -1);
    }
  }

  &--hide-arrow {
    > #{$root}__headline::after {
      display: none;
    }

    > #{$root}__headline {
      cursor: default;
    }
  }

  &__headline {
    padding: 15px 0;
    font-size: $fontSizeFont;
    font-weight: $fontWeightSemiBold;
    line-height: 1.4;
    position: relative;
    margin: 0;
    cursor: pointer;

    @include iconFont(arrowDownBold, after) {
      color: $accordionContentArrowColor;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%) scale(1, 1);
      transition: transform 300ms;
    }
  }

  &__content {
    .accordion & {
      overflow: hidden;
      transition: max-height 500ms cubic-bezier(.4, 1, .5, 1);
      border-bottom: 1px solid $grayColor;
    }

    .user-area & {
      border-bottom: $accordionContentBorderBottomInUserArea;
    }

    .accordion--open & {
      max-height: 20000px;
    }

    .accordion--closed & {
      max-height: 0;
    }
  }

  &__inner {
    margin: 0;
    padding: 10px 0 30px;
  }

  &__anchor {
    position: absolute;
    top: -55px;

    @media (min-width: $screen-tablet-portrait) {
      top: -100px;
    }
  }
}
