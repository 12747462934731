.empty-search-info {
  @include container();

  &__headline {
    margin-bottom: 30px;
  }

  &__col-head {
    @include fontSize($fontSizeMedium);
    font-weight: $fontWeightSemiBold;
    margin-bottom: 15px;
  }

  &__search {
    position: relative;

    .search-autosuggest__input {
      padding: 0;
    }
  }

  &__text {
    line-height: 150%;
  }
}
