.burger-nav-tree {
  @include clearList();
  margin: 0;
  padding: 0 15px 15px;
  position: relative;
  display: block;
  background: $backgroundWhiteColor;

  &__subcategories {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translate3d(100%, 0, 0);
    transition: transform 150ms;
    background: $backgroundWhiteColor;

    &--active {
      transform: translate3d(0, 0, 0);
    }
  }

  &__item {
    margin: 0;
    padding: 0;
    display: block;
    border-bottom: $borderDefaultColor 1px solid;

    &--headline {
      color: $burgerNavTreeHeadlineColor;
      font-size: $burgerNavTreeHeadlineFontSize;
      position: relative;
      padding: 70px 0 25px;
      border: 0;

      a {
        color: inherit;
        text-decoration: none;
      }
    }

    &--teaser {
      overflow: hidden;
      border: 0;

      .image-text-action-teaser {
        margin-bottom: 20px;
      }
    }

    &--category-special {
      padding: 5px 0;
      border: none;
      margin-top: 15px;

      .burger-nav-tree__link {
        text-decoration: underline;
      }
    }

    &--subcategory {
      &_first {
        padding-top: 5px;
      }

      &_last {
        padding-bottom: 5px;
      }
    }

    &--icon {
      display: flex;
      align-items: center;

      & > * {
        flex-grow: 1;
      }

      &::before {
        display: inline-block;
        width: 22px;
        text-align: left;
        color: $burgerNavTreeIconColor;
      }

      &-wishlist {
        @include iconFont(heart);
        margin-top: 20px;
      }

      &-contact {
        @include iconFont(contact);
      }

      &-faq {
        @include iconFont(question);
      }

      &-chat {
        @include iconFont(chat);
      }

      &-service {
        @include iconFont(cloud);
      }
    }

    &--loader {
      @include componentPreloader();
    }
  }

  &__link {
    display: block;
    position: relative;
    padding: 10px 0;
    color: $burgerNavTreeLinkColor;
    font-weight: $burgerNavTreeLinkFontWeight;
    font-size: $burgerNavTreeLinkFontSize;
    text-decoration: none;
    cursor: pointer;

    &:visited {
      color: $burgerNavTreeLinkColor;
    }

    &--highlighted {
      color: $burgerNavTreeLinkHighlightedColor;
    }

    &--arrow {
      @include iconFont(arrowRight) {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 12px;
        color: $burgerNavTreeArrowRightColor;
      }
    }

    &--loader {
      width: 100px;
      height: 100%;
      display: block;
      background: $grayLightColor;
      color: $grayLightColor;
      border-radius: 4px;
      margin-left: 8px;

      &-alt {
        width: 120px;
      }
    }
  }

  &__back {
    color: $textBaseColor;
    font-size: $baseFontSize;
    padding: 15px 0 15px 20px;
    position: absolute;
    z-index: 1;
    top: 0;
    cursor: pointer;

    @include iconFont(arrowRight) {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      font-size: 10px;
      color: $burgerNavTreeArrowRightColor;
    }
  }
}
