@import '../../../bps/components/accordion/accordion.scss';

.accordion {
  $root: &;

  &__headline {
    @include iconFont(arrowDown, after) {
      color: $accordionContentArrowColor;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%) scale(1, 1);
      transition: transform 300ms;
    }
  }
}
