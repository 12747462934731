@import '../../../bps/components/processTeaser/processTeaser.scss';

.process-teaser {
  $root: &;

  &__headline.headline {
    padding: 0 0 15px !important;
    margin: 0;
    font-size: 18px;
    font-weight: $fontWeightSemiBold;
    text-align: center;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 24px;
    }

    #{$root}--white & {
      color: $processTeaserWhiteColor !important;
    }
  }

  &__icon {
    &.icon--bigger {
      &::before {
        font-size: 45px;

        @media (min-width: $screen-tablet-portrait) {
          font-size: 60px;
        }
      }
    }
  }

  &--abo {
    #{$root}__container {
      @media (min-width: $screen-tablet-portrait) {
        margin: 0 -20px;
        flex-wrap: wrap;
      }

      @media (min-width: $screen-tablet-landscape) {
        flex-wrap: nowrap;
      }
    }

    #{$root}__block {
      padding: 0 20px;
      margin: 0 0 20px;

      @media (min-width: $screen-tablet-portrait) and (max-width: $screen-tablet-portrait-max) {
        width: 50%;
        max-width: 50% !important;
      }

      @media (min-width: $screen-tablet-landscape) {
        max-width: 330px;
        width: 15%;
      }
    }

    h3 {
      margin: 0 0 5px;
      font-size: 20px;
    }

    p {
      margin: 9px 0;
      font-size: 18px;
      line-height: 1.2;
    }

    #{$root}__icon {
      margin: 0 0 10px;

      &::before {
        font-size: 34px;
        color: $aboColor;
      }

      &.icon--filled {
        &::before {
          color: $whiteColor;
        }

        &::after {
          background: $aboColor;
        }
      }

      &.icon--aboEuro,
      &.icon--aboAward,
      &.icon--aboBook,
      &.icon--aboExclusiv,
      &.icon--aboDelivery {
        &::before {
          font-size: 30px;
        }
      }

      &.icon--aboQuality,
      &.icon--aboThanks,
      &.icon--aboWahl {
        &::before {
          font-size: 40px;
        }
      }

      &.icon--aboAuswahl,
      &.icon--aboFlexibel,
      &.icon--aboGeniessen,
      &.icon--aboGeschenk {
        &::before {
          font-size: 50px;
        }
      }
    }
  }
}
