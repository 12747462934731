.html-block {
  &__abo-category-navigation {
    margin: -20px 0 40px;

    @media (min-width: $screen-mobile-landscape) {
      margin: 0 0 40px;
    }
  }

  .abo-category-navigation {
    $borderWidth: 3px;
    $linkColor: $grayLighterColor;
    $linkColorHover: $grayColor;
    $linkColorActive: $blackColor;
    // needed because we want to scroll OVER the element
    // so that the page header does not cover this navigation
    $scrollToTopOffset: 140px;
    padding-top: $scrollToTopOffset;
    margin-top: -$scrollToTopOffset;

    &__list {
      list-style: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 1100px;
      margin: 0 auto;
      border-bottom: none;

      @media (min-width: $screen-mobile-landscape) {
        flex-direction: row;
        border-bottom: $borderWidth solid $linkColor;
      }
    }

    &__link {
      @include fontSize($fontSizeXLarge);
      font-weight: $fontWeightBold;
      display: block;
      width: 125px;
      padding: 5px 0;
      margin-bottom: 10px;
      color: $linkColor;
      text-decoration: none;
      cursor: pointer;
      text-align: center;
      border-bottom: $borderWidth solid $linkColor;

      @media (min-width: $screen-mobile-landscape) {
        width: auto;
        padding: 0 15px;
        margin-bottom: 0;
        border-bottom: none;
      }

      &:not(.abo-category-navigation__link--active):hover {
        color: $linkColorHover;
        border-bottom: $borderWidth solid $linkColorHover;

        @media (min-width: $screen-mobile-landscape) {
          margin-bottom: -$borderWidth;
        }
      }

      &--active {
        color: $linkColorActive;
        border-bottom: $borderWidth solid $linkColorActive;

        @media (min-width: $screen-mobile-landscape) {
          margin-bottom: -$borderWidth;
        }
      }
    }

    &--recurring-order {
      margin: 0 0 48px;

      @media (min-width: $screen-mobile-landscape) {
        margin: 90px 0 48px;
      }
    }
  }
}
