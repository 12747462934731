@import '../../../bps/components/productsSort/productsSort.scss';

.products-sort {
  .select {
    &__wrapper::before {
      content: map-get($icons, arrowDown);
    }

    @media (min-width: $screen-tablet-portrait) {
      margin: 0;
    }
  }
}

/* stylelint-disable-next-line */
products-sort,
[is="products-sort"] {
  @media (min-width: $screen-tablet-portrait) {
    min-height: 44px;
    margin: 0 0 7px;
  }
}
