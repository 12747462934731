.user-shortcut {
  position: relative;

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    text-align: center;
    font-style: normal;
    position: relative;
    color: $headerIconColor;
    background: $headerIconBackgroundColor;
    padding: 20px 5px;
    min-width: 50px;
    max-width: 124px;

    @media (min-width: $screen-tablet-portrait) {
      padding: 20px 5px 0;
      min-width: 90px;
    }

    &::before {
      font-size: 22px;
      color: inherit;
    }

    &--login {
      @include iconFont(user);

      &.user-shortcut__link--active {
        @include iconFont(checked, after);

        &::before {
          color: $loggedInIconColor;
          font-size: 14px;
          margin: 0 0 8px;

          @media (min-width: $screen-tablet-portrait) {
            font-size: 14px;
            margin: -1px 0 9px;
          }
        }

        &::after {
          position: absolute;
          top: 30px;
          left: 54%;
          font-weight: $fontWeightBold;

          @media (min-width: $screen-tablet-portrait) {
            left: 51%;
            top: 28px;
          }
        }
      }
    }

    &--wishlist {
      @include iconFont(heartFilled, before);

      @include iconFont(heartFilled, after) {
        color: $productBoxWishlistColor;
        position: absolute;
        font-size: 22px;
        display: none;
        top: 20px;
        left: 0;
        right: 0;
      }

      &::before {
        .user-shortcut__notification-badge--active & {
          color: $productBoxWishlistColor;
        }
      }

      &::after {
        .user-shortcut__notification-badge--active & {
          color: $productBoxWishlistColor;
          display: block;
          opacity: 1;
          position: absolute;
          font-size: 22px;
        }

        .user-shortcut__notification-badge--update & {
          animation: pulseHeart;
          animation-duration: 2s;
        }
      }
    }

    &--cart {
      @include iconFont(cart);
    }

    &:hover {
      color: $headerIconHoverColor;
      background: $headerIconHoverBackgroundColor;

      .user-shortcut__label {
        &::after {
          box-shadow: 0 -4px 6px 0 $headerIconHoverBackgroundColor inset;
        }
      }
    }
  }

  &__label {
    display: none;

    @media (min-width: $screen-tablet-portrait) {
      display: block;
      font-size: $fontSizeSmall;
      font-weight: $fontWeightRegular;
      color: inherit;
      padding: 2px 6px 0;
      position: relative;
      overflow: hidden;
      min-height: 35px;
      max-height: 35px;
      max-width: 135px;

      &::after {
        content: "";
        box-shadow: 0 -4px 6px 0 $whiteColor inset;
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  &__notification-badge-wrapper {
    position: absolute;
    top: 10px;
    left: 55%;
    margin: auto;

    @media (min-width: $screen-tablet-portrait) {
      left: 55%;
    }
  }

  &__notification-badge {
    background: $badgetBackgroundColor;
    color: $badgetTextColor;
    position: relative;
    font-size: 13px;
    min-width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 20px;
    text-align: center;
    padding: 0 6px;
    display: none;

    &--active {
      display: block;
    }

    &--update {
      animation: pulse;
      animation-duration: 2s;

      &.header__user-shortcut--wishlist {
        animation: none;
      }
    }
  }

  &__flyout {
    width: calc(100vw - 30px); // stylelint-disable-line  plugin/no-unsupported-browser-features
    max-width: 320px;
    position: absolute;
    background: $backgroundWhiteColor;
    border-radius: 5px;
    top: 100%;
    margin-top: -5px;
    padding: 20px;
    box-shadow: 0 0 5px rgb(0 0 0 / .3);
    z-index: $indexBasketFlyout;
    right: 0;

    @media (max-width: $screen-mobile-landscape-max) {
      &:not(.minicart--wishlist) {
        right: -10px;
      }
    }
  }
}
