.sibling-navigation {
  position: relative;
  margin: 16px 0 8px;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 24px;
    background: $siblingNavigationBackground;
  }

  &__container {
    overflow-x: auto;
    padding-bottom: 8px;
  }

  &__list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    font-size: $siblingNavigationItemFontSize;
    display: flex;
  }

  &__label {
    color: $siblingNavigationColor;
    text-decoration: none;
    display: inline-block;
    padding: 4px 0;
    white-space: nowrap;

    &--active {
      color: $siblingNavigationActiveColor;
      font-weight: $siblingNavigationActiveFontWeight;
      border-bottom: $siblingNavigationActiveBorderBottom;
    }
  }

  &__count {
    color: $siblingNavigationCountColor;
    display: inline-block;
    padding: 4px 24px 4px 6px;
  }
}
