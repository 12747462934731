.burger-menu {
  width: 100%;
  position: fixed;
  top: 125px;
  left: 0;
  height: calc(100% - 125px);
  transform: translateX(-510px);
  transition: transform 500ms;
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
  z-index: $indexBurgerMenu;
  background: $backgroundWhiteColor;

  @media (min-width: $screen-mobile-sm) {
    top: 126px;
    height: calc(100% - 126px);
  }

  @media (min-width: $screen-tablet-portrait) {
    top: 130px;
    left: 0;
    height: calc(100% - 130px);
    max-width: 425px;
  }

  .page-main--sticky-active & {
    top: 90px;
    height: calc(100% - 90px);

    @media (min-width: $screen-mobile-sm) {
      top: 95px;
      height: calc(100% - 95px);
    }

    @media (min-width: $screen-tablet-portrait) {
      top: 100px;
      height: calc(100% - 100px);
    }

    @media (min-width: $screen-desktop-sm) {
      top: 79px;
      height: calc(100% - 79px);
    }
  }

  .native-app & {
    z-index: $indexBurgerMenuNativeApp;
    top: 120px;
    height: 100%;
  }

  &--open {
    transform: translateX(0);

    .native-app & {
      max-height: calc(100% - 180px);
    }
  }

  &__close-button {
    display: block;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    padding: 15px;
    cursor: pointer;
    font-size: $fontSizeMedium;

    @include iconFont(close) {
      color: $textBaseColor;
      font-size: $fontSizeFont;
    }

    // remove this after refactoring of burgermenu when menu items gets configurable
    .native-app & {
      display: none;
    }
  }

  &__loader {
    @include spinner();
    animation: fadeIn .5s;
    animation-delay: 20ms;
  }
}

// Placeholder style
/* stylelint-disable-next-line */
burger-menu,
[is="burger-menu"] {
  display: none;
}
