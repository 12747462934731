.side-panel {
  $root: &;
  width: 100vw;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  background-color: $sidePanelBackground;
  z-index: $indexLightbox;
  max-width: $sidePanelMaxWidth;
  transition: transform .3s ease-in-out;

  &--checkout {
    max-width: $sidePanelCheckoutMaxWidth;

    #{$root}__container {
      background: $sidePanelCheckoutBackground;
      margin-top: 0;
      height: 100vh;
    }
  }

  &.checkout-page__sidepanel-form--billing-form {
    #{$root}__container {
      background: $sidePanelCheckoutFormBackground;
    }
  }

  &__close {
    color: $blackColor;
    padding: 12px;
    position: absolute;
    right: $sidePanelCloseIconRight;
    top: $sidePanelCloseIconTop;
    cursor: pointer;
    background: $sidePanelCloseBackground;
    z-index: 1;
    border-radius: 100%;

    @include iconFont(#{$sidePanelCloseIconName}) {
      font-size: $sidePanelCloseIconFontSize;
    }
  }

  &__container {
    @include customScrollbar();
    overflow: hidden auto;
    margin-top: $sidePanelContainerTop;
    height: calc(100vh - $sidePanelContainerTop);
  }

  &--cart {
    max-width: $sidePanelCartMaxWidth;
    background: $whiteColor;

    #{$root}__close {
      background: $whiteColor;
    }
  }

  &--cart-mobile {
    top: auto;
    bottom: 0;
    height: auto;
    max-width: none;

    #{$root}__container {
      height: auto;
    }

    #{$root}__close {
      width: 100%;
      top: -5px;
      right: 0;
      text-align: center;
      background: transparent;

      &::before {
        content: map-get($icons, 'arrowDown');
        font-size: 24px;
      }
    }

    &.side-panel-slide-enter-from,
    &.side-panel-slide-leave-to {
      transform: translateY(100%);
    }
  }
}

.side-panel-slide-enter-from,
.side-panel-slide-leave-to {
  transform: translateX(100%);
}
