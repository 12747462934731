.product-box-wide {
  display: flex;
  position: relative;
  background: $whiteColor;
  padding: 16px;
  min-height: 260px;
  border: $productBoxListBorder;

  &__segment-image {
    width: 35%;
    max-width: 420px;

    @media (min-width: $screen-laptop) {
      width: 45%;
    }
  }

  &__segment-center {
    flex: 1 1;
    margin: 0 24px;
  }

  &__segment-conversion {
    width: 150px;
    position: relative;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: flex-end;
    text-align: right;
  }

  &__image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;

    &--bottle {
      background: $grayLighterColor;
      background: $productBoxWideBottleBackground;
      padding: 20px;

      img {
        width: auto !important;
      }
    }
  }

  &__image {
    margin: auto;

    &.lazy-load-image {
      min-height: 165px;
    }

    img {
      max-height: 265px;
      position: relative;
      width: 100%;

      .product-box-wide__image-wrapper--bottle & {
        max-height: 220px;
      }
    }
  }

  &__status {
    margin: -5px 0;
  }

  &__price-wrapper--no-price {
    align-self: flex-end;
  }

  &__price {
    margin-bottom: 8px;
  }

  &__product-flags {
    position: absolute;
    z-index: 1;
    top: 12px;
    left: 4px;

    &--conversion {
      top: 40px;
      right: 0;
      left: auto;
    }
  }

  &__button-wishlist {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__header-text {
    padding: 0;
  }

  &__rating-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0 16px;

    .ratings {
      font-size: 13px;
    }
  }

  &__bottle-amount {
    font-size: $fontSizeSmall;
  }

  &__long-description {
    color: $grayDarkColor;
    font-size: $baseFontSize;
    line-height: 125%;
    margin-top: 16px;

    p {
      margin: 0 0 8px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
  }
}
