.placeholder {
  &__container {
    display: block;

    > * {
      width: 100%;
    }
  }

  &__content {
    @include componentPreloader() {
      height: auto;
    }
    min-height: 100px;
  }

  &--full-width {
    width: 100%;
  }

  &--flex {
    display: flex;
  }

  &__hidden {
    visibility: hidden;
  }

  &__absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__slot-hide {
    &[slot] {
      display: none;
    }
  }
}
