@import '../../../bps/components/ul/ul.scss';

.html-block .ul,
.ul {
  &--link-list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-family: $fontPrimaryFamily;
    font-weight: $fontWeightSemiBold;
    font-size: 14px;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 18px;
    }

    .teasergroup_teaser__item & {
      margin: 0 0 -10px;

      @media (min-width: $screen-tablet-portrait) {
        margin: 0 0 -20px;
      }

      @media (min-width: $screen-desktop) {
        margin: 0;
      }
    }

    > li {
      margin: 0;
      padding: 10px 0 6px;
      border-top: 1px solid $textDarkestColor;

      @media (min-width: $screen-tablet-portrait) {
        padding: 12px 0;
      }

      @media (min-width: $screen-tablet-landscape) {
        &:last-child {
          border-bottom: 1px solid $textDarkestColor;
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      > li {
        font-size: 19px;
        font-family: $fontSecondaryFamily;
        font-weight: $fontWeightRegular;
        font-style: italic;
        margin: 2px 0 0;

        @media (min-width: $screen-tablet-portrait) {
          font-size: 29px;
        }

        > a:not(.no-link) {
          @include iconFont(arrowRightThin, after) {
            display: inline-block;
            position: absolute;
            right: 0;
            bottom: 10px;
            font-size: 16px;
          }
        }
      }
    }

    a:not(.no-link) {
      position: relative;
      width: 100%;
      text-decoration: none;
      display: inline-block;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &--checkmark {
    padding: 0;

    li {
      padding-left: 30px;

      @media (min-width: $screen-tablet-portrait) {
        padding-left: 30px;
      }

      &::before {
        left: 9px;
        top: 7px;
        font-size: 13px;
      }
    }
  }
}
