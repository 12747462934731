.pagination {
  color: $paginationTextColor;
  font-size: $paginationBaseFontSize;

  @media (min-width: $screen-tablet-landscape) {
    font-size: $paginationSelectFontSize;
  }

  &__button-container {
    &.pagination__button-container--show-page-buttons {
      flex-basis: 100%;
      display: flex;
      align-items: center;
      justify-content: $paginationButtonsJustifyContent;
    }
  }

  &__pages {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: $paginationPagesFlexGrow;
    margin: 0;
  }

  &__button {
    display: inline-block;
    cursor: pointer;
    color: $paginationButtonTextColor;
    transition: color .2s;
    font-size: $paginationButtonFontSize;
    padding: $paginationButtonPadding;
    margin-top: $paginationButtonMarginTop;

    &:hover {
      color: $paginationButtonTextColor;
    }

    &--prev {
      @include iconFont(arrowLeft);
    }

    &--next {
      @include iconFont(arrowRight);
      margin-right: -10px;

      @media (min-width: $screen-tablet-landscape) {
        margin-right: $paginationButtonRightMargin;
      }
    }

    &::before {
      font-weight: bold;
    }

    &--disabled {
      cursor: not-allowed;
      display: none;

      &:hover {
        color: $textBaseColor;
      }
    }
  }

  &__wrapper {
    position: relative;

    &--disabled {
      &::before {
        content: '';
      }
    }
  }

  &__label,
  &__pages-count {
    font-size: $paginationLabelFontSize;
    line-height: 1.4;

    @media (min-width: $screen-tablet-landscape) {
      font-size: $paginationSelectFontSize;
    }
  }

  &__label {
    color: $paginationLabelColor;
  }

  &__pages-count {
    margin-left: 10px;
    color: $paginationPagesCountColor;
  }

  .select--formless .select__custom-dropdown {
    @include customScrollbar(4px, $grayLighterColor);
    border-right: 2px solid $paginationSelectBackgroundColor;

    &--visible {
      border-bottom: 4px solid $paginationSelectBackgroundColor;
    }
  }

  .select {
    margin: $paginationSelectMargin;
    color: $paginationSelectColor;

    &__wrapper::before {
      color: $paginationSelectArrowColor;
      font-size: $paginationSelectArrowFontSize;
    }

    &__selected-value {
      font-weight: $paginationSelectFontWeight;
      font-size: $paginationBaseFontSize;

      @media (min-width: $screen-tablet-landscape) {
        font-size: $paginationSelectFontSize;
      }
    }

    &__custom-option {
      font-size: $baseFontSize;

      &--selected {
        color: $selectActiveColor;
        font-weight: $selectFontWeightBold;
      }
    }
  }
}
