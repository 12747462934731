.products-sort {
  position: relative;

  @media (min-width: $screen-tablet-portrait) {
    display: flex;
    align-items: center;

    &:hover,
    &:focus {
      outline: none;
    }
  }

  &__label {
    position: relative;
    top: 6px;
    left: 16px;
    font-size: 13px;
    color: $productsSortLabelColor;

    @media (min-width: $screen-tablet-portrait) {
      position: static;
    }

    @media (min-width: $screen-tablet-landscape) {
      font-size: $productsSortLabelFontSize;
      line-height: 1.4;
    }
  }

  .select {
    color: $productsSortSelectColor;
    margin: $productsSortSelectMargin;

    &__wrapper::before {
      color: $productsSortSelectArrowColor;
      font-size: $productsSortSelectArrowFontSize;
    }

    &__custom-dropdown {
      min-width: 250px;
    }

    &__selected-value {
      font-weight: $productsSortSelectFontWeight;
      font-size: 14px;
      color: $productsSortSelectColor;

      @media (min-width: $screen-tablet-landscape) {
        font-size: $productsSortLabelFontSize;
      }
    }

    &__custom-option {
      font-size: $baseFontSize;

      &--selected {
        color: $selectActiveColor;
        font-weight: $selectFontWeightBold;
      }
    }
  }
}

// PLACEHOLDER STYLES

/* stylelint-disable-next-line */
products-sort,
[is="products-sort"] {
  display: none !important;

  @media (min-width: $screen-tablet-portrait) {
    display: none !important;
  }
}
