.origin-highlights {
  margin: -4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__entry {
    padding: 4px;
    vertical-align: baseline;
  }

  &__head {
    @include fontSize($fontSizeXLarge);
    display: inline-block;
    width: auto;
    margin: $originHighlightsMargin;
  }

  &__link {
    background: $originHighlightsBackground;
    color: $originHighlightsColor;
    text-decoration: none;
    padding: $originHighlightsPadding;
    display: inline-block;
    border-radius: $originHighlightsBorderRadius;
    transition: box-shadow .2s;
    border: $originHighlightsBorder;

    &:hover {
      box-shadow: $buttonShadow;
    }

    &:active {
      box-shadow: $buttonShadowActive;
    }
  }
}
