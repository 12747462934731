.newsletter-form {
  &__button {
    min-width: auto;
    width: 100%;
    height: 45px;
    line-height: 45px;
  }

  &__row {
    &--no-bottom-space {
      margin-bottom: 0;
    }
  }

  &__declaration {
    font-size: $fontSizeSmall !important;

    a {
      color: $blackColor;
    }
  }

  .form__group:not(.form__group--row) {
    margin: 10px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
