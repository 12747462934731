.filter-buttons {
  @media (min-width: $screen-mobile-landscape) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__close-head {
    @include iconFont(arrowUpBold, after) {
      margin-left: 4px;
      font-size: 10px;
    }
    display: inline-block;
    padding: 12px 24px;
    letter-spacing: 1.5px;
    color: $whiteColor;
    background: $redDarkColor;
    text-transform: uppercase;
    font-weight: $fontWeightBold;
    line-height: 150%;
  }

  .button--primary {
    &::after {
      display: none;
    }
  }

  &__apply {
    width: 100%;

    @media (min-width: $screen-mobile-landscape) {
      width: auto;
    }

    &:only-child {
      width: 100%;
    }
  }

  &__cancel,
  &__reset {
    margin-top: 16px;
    display: inline-block;
    color: $brandPrimaryColor;
    text-decoration: underline;
    white-space: nowrap;

    @media (min-width: $screen-mobile-landscape) {
      margin: 0;
    }
  }
}
