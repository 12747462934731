.read-more {
  $root: &;

  &__state {
    display: none;

    &:checked ~ #{$root}__content {
      display: initial; // stylelint-disable-line  plugin/no-unsupported-browser-features
    }

    &:checked ~ #{$root}__trigger {
      display: none;
    }
  }

  &__content {
    display: none;
  }

  &__trigger {
    cursor: pointer;
  }

  // vue
  &__container {
    overflow: hidden;
  }

  &__btn {
    font-size: 16px;
    font-weight: $readMoreBtnFontWeight;
    text-decoration: underline;
    margin: 8px 0;
    color: $textGrayColor;
    cursor: pointer;
  }
}
