@import '../../../bps/components/tab/tab.scss';

.tabs-component {
  &-tab {
    color: $grayMediumColor;
    transition: color .2s;

    a {
      border-bottom: transparent 2px solid;
      transition: border-color .2s;

      &::before {
        content: none;
      }

      &:hover {
        border-color: currentColor;
      }
    }

    &:hover {
      color: $grayMediumColor;
    }

    &.is-active {
      color: $blackColor;

      a {
        border-color: $brandSecondaryColor;
      }
    }
  }

  &-tabs {
    margin: 0 0 4px;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
  }
}
