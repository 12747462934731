.countdown-teaser-small {
  $root: &;
  padding: 0;
  margin: 0;
  display: inline-block;
  font-size: 12px;

  &__countdown,
  &__block,
  &__number,
  &__label {
    display: inline-block;
  }

  &__countdown {
    margin: 0 10px 0 0;
    padding: 2px 6px;
    border-radius: 4px;
    background: rgb(255 255 255 / .35);
    top: -2px;
    position: relative;
    min-height: 23px;

    @include iconFont(countdown) {
      color: $whiteColor;
      font-size: 15px;
      margin: 0 2px 0 0;
      top: 2px;
      position: relative;
    }
  }

  &__block {
    margin: 0;
  }

  &__number {
    font-weight: $fontWeightSemiBold;
    float: left;
    min-width: 15px;
    text-align: right;
  }

  &__label {
    font-weight: $fontWeightThin;
  }
}
