.similar-wines-teaser {
  &__cinema-row {
    order: 2;

    @media (min-width: $screen-tablet-portrait) {
      display: flex;
      align-items: flex-end;
      flex-flow: row wrap;
    }
  }

  &__hero-wine-wrapper {
    width: 100%;

    @media (min-width: $screen-tablet-portrait) {
      width: 50%;
      display: flex;
      justify-content: center;
    }

    @media (min-width: $screen-tablet-landscape) {
      width: 45%;
    }
  }

  &__hero-wine {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-header {
      text-align: center;
      margin: 0 0 20px;
    }

    &-image {
      img {
        height: 350px;
        max-width: 100%;
      }
    }

    &-button {
      margin: -20px 0 20px;

      @media (min-width: $screen-tablet-portrait) {
        margin: -20px 0 0;
      }

      .button {
        min-width: 200px;
      }
    }
  }

  &__cinema {
    width: 100%;

    @media (min-width: $screen-tablet-portrait) {
      width: 50%;
    }

    @media (min-width: $screen-tablet-landscape) {
      width: 55%;
    }
  }

  .cinema-teaser__headline {
    color: $similarWinesTeaserHeadlineColor;
  }

  .cinema-teaser__link {
    margin: 30px 0 0;
  }
}
