.tip-teaser {
  $root: &;
  position: relative;
  color: $whiteColor;
  background: $blackColor;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &--no-bg {
    background: none;
  }

  &--with-product {
    @media (min-width: $screen-laptop) {
      margin: 60px 0;
    }
  }

  @media (min-width: $screen-tablet-portrait) {
    background: transparent;
    min-height: 350px;
  }

  &__container {
    text-align: center;
    display: flex;
    flex-direction: column;
    max-width: 440px;
    margin: 0 auto;
    padding: 24px 16px;
    z-index: 3;
    position: relative;

    @media (min-width: $screen-tablet-landscape) {
      max-width: 500px;
    }
  }

  &__head {
    @include fontSize($fontSizeXXXLarge);
    text-transform: uppercase;
    font-weight: $fontWeightBold;
    margin-bottom: 4px;
  }

  &__head-with-heart {
    @include fontSize($fontSizeXXXLarge);

    @include iconFont(heart, before) {
      margin-right: 12px;
    }
    text-transform: uppercase;
    font-weight: $fontWeightBold;
    margin-bottom: 16px;
  }

  &__text {
    @include fontSize($baseFontSize);
    font-weight: $fontWeightThin;
    line-height: 1.6;
    margin: 4px 0;
  }

  &__button {
    margin-top: 16px;
    text-decoration: none !important;
    color: inherit !important;
    border-color: inherit !important;

    &:hover {
      color: inherit !important;
      box-shadow: 0 0 0 1px transparent !important;
    }
  }

  &__product {
    margin: 0 auto;
    display: block;
    z-index: 2;
    padding-bottom: 8px;
    text-align: center;
    position: relative;

    img {
      max-height: 230px;

      @media (min-width: $screen-tablet-portrait) {
        max-height: 280px;
      }

      @media (min-width: $screen-tablet-landscape) {
        max-height: none;
      }
    }

    @media (min-width: $screen-tablet-portrait) {
      position: absolute;
      top: 0;
      right: 0;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
  }

  &__bg-container {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    flex-direction: column;
    z-index: 0;
  }

  &__background {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: center;

    img {
      max-width: none;

      @media (min-width: $screen-tablet-landscape) {
        height: auto;
      }
    }

    &--width-100 {
      height: auto;
      min-width: 100%;
    }
  }
}
