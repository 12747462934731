.go-top {
  visibility: hidden;
  text-decoration: none;
  border-radius: 100%;
  display: block;
  transform: translateY(-50%);
  z-index: 4;
  cursor: pointer;
  width: $goTopWidthMobile;
  height: $goTopWidthMobile;
  transition: background .15s linear, bottom .3s ease-in-out;
  color: $goTopColor;
  background: $goTopBackgroundColor;
  box-shadow: $goTopBoxShadow;
  font-size: $goTopFontSizeMobile;
  position: fixed;
  bottom: -50px;

  @media (min-width: $screen-desktop-xl) {
    font-size: $goTopFontSizeDesktop;
    width: $goTopWidthDesktop;
    height: $goTopWidthDesktop;
  }

  @include iconFont($goTopIcon) {
    font-size: inherit;
    color: inherit;
    transition: color .2s;
    text-align: center;
    text-shadow: $goTopIconTextShadow;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -54%);
  }

  .filter--open & {
    display: none;
  }

  &:hover {
    color: $goTopHoverColor;
    background: $goTopHoverBackgroundColor;
  }

  &__wrapper {
    @include container($maxWidthOuter);
    position: relative;
    z-index: 550;
  }

  &--sticky {
    visibility: visible;
    margin-bottom: 0;
    bottom: 10px;
    right: $goTopIconRightMobile;

    @media (min-width: 1364px) {
      bottom: 35px;
      right: calc(50% - 672px);
    }
  }

  &--with-bottom-space {
    @media (max-width: $screen-tablet-portrait - 1) {
      bottom: 70px;
    }
  }
}
