.quickfacts {
  @include clearList();
  line-height: 130%;

  &__item {
    display: inline-block;
    position: relative;
  }

  &__label {
    display: inline-block;
  }

  &__value {
    display: block;
  }

  &__value-item {
    &:not(:last-child)::after {
      content: ",";
      display: inline-block;
      padding: 0 3px 0 0;
    }

    &--linked {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
