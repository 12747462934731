.services-list-teaser {
  $root: &;

  &__wrapper {
    margin: -10px -5px;
    width: calc(100% + 10px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;

    @media (min-width: $screen-tablet-portrait) {
      margin: -10px;
    }
  }

  &--size {
    &_1 {
      #{$root}__service {
        width: 100%;
        max-width: 100%;
      }
    }

    &_2 {
      #{$root}__service {
        width: 50%;
        max-width: 50%;
      }
    }

    &_3 {
      #{$root}__service {
        width: 50%;
        max-width: 50%;

        @media (min-width: $screen-tablet-portrait) {
          width: calc(100% / 3);
        }
      }
    }

    &_4 {
      #{$root}__service {
        width: 50%;
        max-width: 50%;

        @media (min-width: $screen-tablet-portrait) {
          width: 25%;
          max-width: 25%;
        }
      }

      .teaser-in-column & #{$root}__service {
        @media (min-width: $screen-desktop) {
          width: 50%;
          max-width: 50%;

          &:nth-child(even) {
            border-right: none;
          }
        }
      }
    }

    &_5 {
      #{$root}__service {
        width: 50%;
        max-width: 50%;

        @media (min-width: $screen-tablet-portrait) {
          width: 20%;
          max-width: 20%;
        }
      }

      .teaser-in-column & #{$root}__service {
        @media (min-width: $screen-tablet-portrait) {
          width: 50%;
          max-width: 50%;

          &:nth-child(even) {
            border: none;
          }
        }

        @media (min-width: $screen-desktop) {
          width: calc(100% / 3);
          max-width: calc(100% / 3);

          &:nth-child(3n) {
            border: none;
          }
        }
      }
    }

    &_6 {
      #{$root}__service {
        width: 50%;

        @media (min-width: $screen-tablet-portrait) {
          width: calc(100% / 3);
          max-width: calc(100% / 3);

          &:nth-child(3) {
            border: none;
          }
        }
      }

      .teaser-in-column & #{$root}__service {
        @media (min-width: $screen-tablet-portrait) {
          width: 50%;
          max-width: 50%;

          &:nth-child(odd) {
            border-right: $serviceTeaserBorder;
          }

          &:nth-child(even) {
            border: none;
          }
        }

        @media (min-width: $screen-desktop) {
          width: calc(100% / 3);
          max-width: calc(100% / 3);

          &:nth-child(3n) {
            border: none;
          }

          &:nth-child(even) {
            border-right: $serviceTeaserBorder;
          }
        }
      }
    }

    &_7 {
      #{$root}__service {
        width: 50%;
        max-width: 50%;

        @media (min-width: $screen-tablet-portrait) {
          width: calc(100% / 3);
          max-width: calc(100% / 3);

          &:nth-child(3n) {
            border-right: none;
          }
        }
      }
    }

    &_8 {
      #{$root}__service {
        width: 50%;
        max-width: 50%;

        @media (min-width: $screen-tablet-portrait) {
          width: 25%;
          max-width: 25%;

          &:nth-child(4) {
            border-right: none;
          }
        }
      }
    }
  }

  &__service {
    padding: 0 10px;
    margin: 10px 0;
    flex-grow: 1;
    text-align: center;

    &:nth-child(odd) {
      border-right: $serviceTeaserBorder;
    }

    @media (min-width: $screen-tablet-portrait) {
      border-right: $serviceTeaserBorder;
    }

    &:last-child {
      border-right: none !important;
    }

    &.service-teaser__button {
      padding: 0 5px;
      margin: 5px 0;

      @media (min-width: $screen-tablet-portrait) {
        padding: 0 10px;
        margin: 10px 0;
      }
    }
  }

  .sale-box & {
    .services-list-teaser__service {
      width: 50% !important;
    }

    .service-teaser::before {
      font-size: 30px;
      position: absolute;
      left: 0;
      margin: auto;
      top: 6px;
      bottom: 0;
      width: 55px;
      color: $brandPrimaryColor;
    }

    .service-teaser__headline {
      text-align: left;
      padding: 5px 0 5px 50px;
      margin: 0;
    }

    .service-teaser__subline {
      text-align: left;
      padding: 0 0 0 50px;
      margin: 0;
    }

    .services-list-teaser__service,
    .services-list-teaser__service:nth-child(odd) {
      border-right: 0 none;
    }
  }
}
