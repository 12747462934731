.navigation {
  $root: &;

  @media (min-width: $screen-desktop-sm) {
    position: relative;
    min-height: 20px;

    &__list {
      @include clearList();
      display: flex;
      justify-content: flex-start;
      align-items: normal;
      width: 75%;

      &--width_auto {
        display: inline-block;
      }
    }

    &__link {
      display: block;
      padding: 0 10px;
      transition: border .2s, color .2s;
      color: $navigationElementColor;
      font-family: $fontFamily;
      font-size: $navigationLinkFontSize;
      font-weight: $navigationLinkFontWeight;
      text-decoration: none;
      white-space: nowrap;
      text-align: center;
      position: relative;
      letter-spacing: 1.5px;

      &--all {
        box-shadow: 0 -2px 10px 0 rgb(0 0 0 / .1);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 200px;
        text-align: center;
      }

      &:visited {
        color: $textBaseColor;
      }
    }

    &__flyout {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: -4000px;
      left: -120px;
      width: calc(100vw - 70px);
      transition: visibility .2s, opacity .2s;
      min-height: 190px;
      z-index: $indexHeader;
      padding-top: 4%;

      @media screen and (min-width: $screen-desktop) {
        left: -140px;
      }

      &--all {
        left: 0;
        right: auto;
        width: auto;
      }
    }

    &__element {
      display: inline-block;
      padding: 5px 0;
      border-bottom: $navigationElementBorderBottom;
      transition: border-bottom-color .2s linear;

      &:hover {
        color: $navigationElementHoverColor;
        border-bottom: $navigationElementHoverBorderBottom;

        #{$root} {
          &__link {
            color: $navigationElementHoverColor;
          }

          &__flyout {
            visibility: visible;
            opacity: 1;
            top: 100%;
          }
        }
      }

      &--hover,
      &--visible {
        #{$root} {
          &__flyout {
            visibility: visible;
            opacity: 1;
            top: 100%;
          }
        }
      }
    }
  }
}
