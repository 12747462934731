.scroll-to-content-button {
  display: inline-block;
  letter-spacing: $scrollToContentButtonLetterSpacing;
  color: $scrollToContentButtonColor;
  text-decoration: $scrollToContentButtonDecoration;
  text-transform: $scrollToContentButtonTransform;
  cursor: pointer;
}

// Placeholder style
scroll-to-content-button {
  min-height: 1.1em;
  min-width: 2px;
  display: block;
  text-decoration: none !important;
}
